/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton, DatePicker, EditButton, Form, FormInstance, Icons, Input, List, notification, Row, Select, Space, Table, TextField, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { IParcelBox, IParcelImport, IProperties, IUnit } from "interfaces";
// import Moment from 'moment';
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useRef, useState } from "react";
import { TOKEN_KEY } from "../../constants";
import CsvImport from './CsvImport';


export const ParcelInList: React.FC<IResourceComponentsProps> = () => {
    const API_URL = useApiUrl();
    dayjs.extend(customParseFormat);
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Parcel In");
    let data_property = Identity_data?.property?.id ? Identity_data?.property?.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [Rc, setRc] = useState<any>(undefined);
    const [trackingNo, setTrackingNo] = useState<any>(undefined);
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    
    const [dataParcelIn, setDataParcelIn] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(undefined);
    const [loadingParcelIn, setLoadingParcelIn] = useState<boolean>(true);
    const formRef = useRef<FormInstance<any>>(null);
    const getData = async ()=>{
        try {
            setLoadingParcelIn(true)
            let apiEndPoint = API_URL + `/parcel-ins?populate=parcel_lists&populate=user_profile&populate=unit&populate=property&populate=createBy&populate=updateBy&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}&filters[unit][id][$null]=false`
            if(selunit){
                apiEndPoint += `&filters[unit][id][$eq]=${selunit}`
            }
            if(data_property){
                apiEndPoint += `&filters[property][id][$eq]=${data_property}`
            }
            else if(vPropertyInput){
                apiEndPoint += `&filters[property][id][$eq]=${vPropertyInput}`
            }
            if(vDate2){
                apiEndPoint += `&filters[in_date][$contains]=${vDate2}`
            }
            if(Rc){
                apiEndPoint += `&filters[parcel_lists][running_code][$contains]=${Rc}`
            }
            if(trackingNo){
                apiEndPoint += `&filters[parcel_lists][tracking_no][$contains]=${trackingNo}`
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const parcelInList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let user_profile = null
                        let unit = null
                        let property = null
                        let parcelListCount = 0
                        let running_code = '-'
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.user_profile?.data){
                            user_profile = {id: attributes?.user_profile?.data?.id , ...attributes?.user_profile.data.attributes}
                        }
                        if(attributes?.unit?.data){
                            unit = {id: attributes?.unit.data.id , ...attributes?.unit.data.attributes}
                        }
                        if(attributes?.property?.data){
                            if(attributes?.property?.data?.attributes?.aqi_response?.data){
                                attributes.property.data.attributes.aqi_response = attributes?.property?.data?.attributes?.aqi_response?.data
                            }
                            property = {id: attributes?.property.data.id , ...attributes?.property.data.attributes}
                        }
                        try {
                            if(attributes?.parcel_lists?.data && attributes?.parcel_lists?.data?.length > 0){
                                running_code = attributes?.parcel_lists?.data[0]?.attributes?.running_code
                            }
                            else{
                                running_code = '-'
                            }
                        } catch (error) {
                            running_code = '-'
                        }
                        parcelListCount = attributes?.parcel_lists?.data?.length || 0
                        const result = {
                            id: item.id,
                            note: attributes?.note,
                            in_date: attributes?.in_date,
                            customer_name: attributes?.customer_name,
                            create_date: attributes?.create_date,
                            createdAt: attributes?.createdAt,
                            updatedAt: attributes?.updatedAt,
                            createBy: createBy,
                            updateBy: updateBy,
                            user_profile: user_profile,
                            unit: unit,
                            property: property,
                            parcel_lists: parcelListCount,
                            running_code: running_code
                        }
                        return result
                    })
                    setDataParcelIn(parcelInList)
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingParcelIn(false)
                }).catch((e) => {
                    console.log(e) 
                    setLoadingParcelIn(false)
                });
        } catch (error) {
            setLoadingParcelIn(false)
        }
    }
    // const { tableProps,searchFormProps, tableQueryResult} = useTable<any,HttpError, any>({
    //     metaData: {
    //         // populate: '*',
    //         // locale:['th']
    //         populate: [ 'parcel_lists', 'parcel_lists.tracking_no', 'unit', 'unit.unit_name', 'user_profile', 'user_profile.first_name', 'property', 'property.property_name', 'createBy', 'createBy.first_name', 'updateBy', 'updateBy.first_name'  ]
    //     },
    //     onSearch: (params) => {
    //         const filters: CrudFilters = [];
    //         filters.push(
    //             {
    //                 field       : "property][id]",
    //                 // operator    : (vPropertyInput || prop_list || prop_id) ? "eq" : "nin",
    //                 operator : "eq",
    //                 value       : vPropertyInput ? vPropertyInput: prop_list ? prop_list: prop_id ? prop_id : null,
    //             },
    //             {
    //                 field       : "unit][id]",
    //                 operator    : "eq",
    //                 value       : selunit
    //             },
    //             {
    //                 field       : "in_date]",
    //                 operator    : "contains",
    //                 value       : vDate2
    //             },
    //             {
    //                 field       : "parcel_lists][running_code]",
    //                 operator    : "contains",
    //                 value       : Rc
    //             },
    //             {
    //                 field       : "parcel_lists][tracking_no]",
    //                 operator    : "contains",
    //                 value       : trackingNo
    //             },
    //         )
    //         return filters;
    //     },
    //     permanentSorter: [
    //         {
    //             field: 'id',
    //             order: 'desc',
    //         },
    //     ],
    //     permanentFilter:[
    //         {
    //             field : "unit][id]",
    //             operator : "null",
    //             value: false
    //         }
    //     ],
    //     initialFilter:[
    //         {
    //             field : "property][id]",
    //             operator: (prop_list || prop_id) ? "eq" : "nin",
    //             value: prop_list ? prop_list: prop_id ? prop_id : null,
    //         }
    //     ],
    //     // initialPageSize:2000,
    //     // fetchSize: 1000,
    //     queryOptions:{onSuccess(data) {
    //         // console.log(">>> data",data?.data)
    //     },}
    // });


    // const { listProps: parcelData, queryResult: { refetch: refetch2 } } = useSimpleList({
    //     resource: "parcel-ins",
    //     metaData: {
    //         populate: [ 'parcel_lists', 'parcel_lists.tracking_no', 'unit', 'unit.unit_name', 'user_profile', 'user_profile.first_name', 'property', 'property.property_name', 'createBy', 'createBy.first_name', 'updateBy', 'updateBy.first_name'  ]
    //     },
    //     pagination:{
    //         pageSize: 1000,
    //     },
    //     initialSorter: [
    //         {
    //             field: "id",
    //             order: "desc",
    //         },
    //     ],
    //     permanentFilter: [
    //         {
    //             field : "property][id]",
    //             operator : data_property !== null ? "in" : "ne",
    //             value: data_property !== null ? data_property : null
    //         }
    //     ],
    //     queryOptions: {
    //       onSuccess: (data:any) => {
    //         console.log('data xxxx : ', data.data)
    //         let dataLoad: any = data?.data;

    //         dataParcelIn.push(dataLoad)

    //       },
    //     },
    // });

    // const queryParcelInData = useList<IParcelIn>({ 
    //     metaData: {
    //         populate: [ 'parcel_lists', 'parcel_lists.tracking_no', 'unit', 'unit.unit_name', 'user_profile', 'user_profile.first_name', 'property', 'property.property_name', 'createBy', 'createBy.first_name', 'updateBy', 'updateBy.first_name'  ]
    //     },
    //     resource: "parcel-ins" ,
    //     config:{
    //         sort: [{ order: "desc", field: "id" }],
    //         // filters: [
    //         //     {
    //         //         field       : "user_type][type_name]",
    //         //         operator    : "nin",
    //         //         value       : ["Customer","Guest"]
    //         //     },
    //         // ],
    //         pagination:{current: 1, pageSize: 1000}
    //     }
    // });

    // // console.log('queryParcelInData', queryParcelInData)

    // let parcelInData:any = [];

    // if(queryParcelInData.status == "success"){
    //     parcelInData = queryParcelInData.data?.data ?  queryParcelInData.data?.data : [];
    // }

    // const categoryQueryResult = useOne<IParcelIn>({
    //     metaData: {
    //         // populate: '*'
    //         populate: [ 'parcel_lists', 'parcel_lists.tracking_no', 'unit', 'unit.unit_name', 'user_profile', 'user_profile.first_name', 'property', 'property.property_name', 'createBy', 'createBy.first_name', 'updateBy', 'updateBy.first_name'  ]

    //     },
    //     resource: "parcel-ins",
    //     id: ''
    // });

    // let paginationLength:any = [];
    // paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    // console.log("paginationLength",paginationLength)
    // console.log('parcelInData', parcelInData)

    // const { confirm } = Modal;
    // const { mutate } = useUpdate<IParcelIn>();
    // const ed_point = "parcel-box-sizes";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    // const { triggerExport, isLoading: exportLoading } = useExport<IParcelType>({
    //     mapData: (item) => {
    //         //console.log(item)
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id,
    //             };
    //         }
    //     },
    // });

    // function onStatusChange(){
    //     searchFormProps.form?.submit();
    // }
    
    // const { mutate: CreateLogDelete }       = useCreate<IEventlog>();

    // const { mutate: createParcelIn } = useCreate<IParcelIn>();
    // const { mutate: createParcelList } = useCreate<IParcelList>();

    const { mutate: createParcelIn2 } = useCreate<IParcelImport>();

    // const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    // const vCurDelStatus                     = {deleteStatus : 'Y'}

    // function updateStatus(id:any, event:any){
    //     // console.log('status : ', vCurDelStatus)
    //     mutate({
    //         resource    : ed_point,
    //         id          : id+"",
    //         // values      : vCurDelStatus,
    //         values : {
    //             updateBy : user_data.user_profile.id + "",
    //             deleteStatus : vCurDelStatus.deleteStatus
    //         },errorNotification:false,
    //         successNotification:false
    //     });

    //     // CreateLogDelete({
    //     //     resource    : log_endpoint,
    //     //     values      : {
    //     //         user_profile    : user_data.user_profile.id,
    //     //         log_time_stamp  : Date.now(), 
    //     //         log_event       : event,
    //     //         menu_event      : 'policy',
    //     //         SysPkID         : id+""
    //     //     }
    //     // });
    // }

    // function showDelConfirm(id:any) {
    //     confirm({
    //         // title   : 'Deactivate Confirm ?',
    //         title   : 'Inactive Confirm ?', 
    //         icon    : <WarningOutlined />,
    //         content : 'Click Confirm to deactivate this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             vCurDelStatus.deleteStatus = 'Y'
    //             updateStatus(id, "Inactive");
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }

    // function showUnlockConfirm(id:any) {
    //     confirm({
    //         title   : 'Activate Confirm ?',
    //         icon    : <QuestionCircleOutlined />,
    //         content : 'Click Confirm to activate this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             vCurDelStatus.deleteStatus = 'N'
    //             updateStatus(id, "Active");
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }

    // const [ vUserID, setUserID ] = useState<any>();
    // const [ vUnitID, setUnitID ] = useState<any>(); 
    // const [ vPropertyID, setPropertyID ] = useState<any>(); 
    // const [ vErrImport, setErrImport ] = useState<any>(); 

    // let k_userID:any;
    // let k_unitID:any;
    // let k_propertyID:any;
    // let UnitAndPropID:any = []

    // let userData:any = [];
    // let propertyData:any = [];

    // console.log('vErrImport', vErrImport)

    // let resultQuery1:any = []
    // let resultQuery2:any = []
    
    // async function callAPI_findUser(v:any){
    //     const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    //     resultQuery1 = await axios.get(API_URL + "/like-systems/findUserProfile?user_email=" + v , { headers: { Authorization: AuthStr } });
    //     // resultQuery1 = await axios.get(API_URL + "/like-systems/findUserProfile?user_email=" + v , { headers: { Authorization: AuthStr } }).then((res) => {
    //     //     // if(res.data !== "nodata"){
    //     //     //     console.log('res', res)
    //     //     //     setUserID(res.data.id);
    //     //     //     // console.log('vUserID xx', vUserID)
    //     //     //     return res.data.id
    //     //     // }else{
    //     //     //     setUserID(null);
    //     //     // }
    //     // });

    //     // console.log('find user ', resultQuery1.data);

    //     // setUserID(resultQuery1.data.id);
    //     // setUserID(resultQuery1.data);
    //     // if(resultQuery1.data !== "nodata"){
    //     //     setUserID(resultQuery1.data.id);
    //     //     // console.log('vUserID xx', vUserID)
    //     // }else{
    //     //     setUserID(null);
    //     // }

    //     // vUserID = resultQuery1.data.id

    //     // k_userID = resultQuery1.data.id;

    //     // userData.push({
    //     //     // key : i,
    //     //     id  : resultQuery1.data.id
    //     // })

    //     // console.log('userData', userData);
    //     // setDataToState('user', resultQuery2.data);

    //     return resultQuery1.data.id
    // }

    // async function callAPI_findUnit(v:any){
    //     const AuthStr = 'Bearer '.concat(TOKEN_KEY); 
    //     resultQuery2 = await axios.get(API_URL + "/like-systems/findUnit?unit_name=" + v , { headers: { Authorization: AuthStr } });
    //     // console.log('find unit and property ', resultQuery2.data);

    //     if(resultQuery2.data == "nodata"){
    //         setErrImport(true)
    //         return [{unitID: "nodata", propertyID: "nodata"}]
    //     }else{
    //         return [{unitID: resultQuery2.data.id, propertyID: resultQuery2.data.property.id}]
    //     }
    // }

    // function setDataToStatet1(unitPropertyData:any, note:any, box_type:any, parcel_size:any, send_type:any, delivery_comp:any, status:any, receiver_name:any, track_no:any){
    //     // console.log('setDataToState 1 data', unitPropertyData)

    //     // console.log('*******************************')
    //     // console.log('unitPropertyData', unitPropertyData)
    //     // console.log('note', note)
    //     // console.log('box_type', box_type)
    //     // console.log('parcel_size', parcel_size)
    //     // console.log('send_type', send_type)
    //     // console.log('delivery_comp', delivery_comp)
    //     // console.log('status', status.toUpperCase())
    //     // console.log('receiver_name', receiver_name)

    //     createParcelIn({
    //         resource : 'parcel-ins',
    //         values : {
    //             property : unitPropertyData.propertyID + "",
    //             unit : unitPropertyData.unitID + "",
    //             // user_profile : vUserID + "",
    //             // customer_name: vUserID === null || vUserID === undefined ? item.customer_name : '', // กรณีที่หา ID ของ user_profile ไม่เจอ
    //             customer_name: receiver_name + "",
    //             note: note + "",
    //             create_date : dayjs(),
    //             createBy: user_data.user_profile.id,
    //             in_date : dayjs()
    //         },
    //         // errorNotification:false,
    //         successNotification:false
    //     },{
    //         onSuccess: async(data: any, link: any) =>{
    //             // console.log('data onSuccess', data)
    //             console.log('parcel_in id >>> ', data.data.data.id) // id ของ parcel in

    //             // setParcelInID(data.data.data.id)
    //             createParcelList({
    //                 resource: 'parcel-lists',
    //                 values: {
    //                     parcel_in: data.data.data.id,       // ID ของ parcel in
    //                     tracking_no: track_no,
    //                     parcel_type: box_type + "",              // ID ของ ประเภทกล่อง ex. brown envelope, brown parcel box, seal bag, white envelope
    //                     parcel_box_size: parcel_size + "",       // ID ของ ขนาดกล่อง ex. extra large size, small size
    //                     parcel_shipping_type: send_type + "",    // ID ของประเภทการขนส่ง ex. standard, COD, EMS
    //                     delivery_company: delivery_comp + "",    // ID ของบริษัทขนส่ง ex. DHL, Kerry, Thailand Post, CJ Logistic
    //                     parcel_status: status.toUpperCase()
    //                 }
    //             })
    //         },
    //     });
    // }

    // function createParcelInList(){
        
    //     // parcelIn_property: unitPropertyData.propertyID + "",
    //     // parcelIn_unit: unitPropertyData.unitID + "",
    //     // parcelIn_customerName: receiver_name + "",
    //     // parcelIn_note: note + "",
    //     // parcelIn_createDate: dayjs(),
    //     // parcelIn_createBy: user_data.user_profile.id,
    //     // parcelIn_inDate: dayjs(),


    //     // parcelList_trackingNo: track_no,
    //     // parcelList_boxType: box_type + "", 
    //     // parcelList_parcelSize: parcel_size + "",
    //     // parcelList_sendType: send_type + "",
    //     // parcelList_deliveryComp: delivery_comp + "",
    //     // parcelList_status: status.toUpperCase()
    //     // console.log('vArrParcelData',vArrParcelData)
    //     // console.log('vArrParcelData',vArrParcelData.length)

    //     for(let i=0; i<=vArrParcelData.length -1; i++){
    //         console.log('count i', i)
    //         createParcelIn({
    //             resource : 'parcel-ins',
    //             values : {
    //                 property : vArrParcelData[i].parcelIn_property + "",
    //                 unit : vArrParcelData[i].parcelIn_unit + "",
    //                 customer_name: vArrParcelData[i].parcelIn_customerName + "",
    //                 note: vArrParcelData[i].parcelIn_note + "",
    //                 create_date : dayjs(),
    //                 createBy: vArrParcelData[i].parcelIn_createBy,
    //                 in_date : dayjs()
    //             },
    //             // errorNotification:false,
    //             successNotification:false
    //         },{
    //             onSuccess: async(data: any, link: any) =>{
    //                 // console.log('data onSuccess', data)
    //                 console.log('parcel_in id >>> ', data.data.data.id) // id ของ parcel in
    
    //                 // setParcelInID(data.data.data.id) 
    //                 createParcelList({
    //                     resource: 'parcel-lists',
    //                     values: {
    //                         parcel_in: data.data.data.id,       // ID ของ parcel in
    //                         tracking_no: vArrParcelData[i].parcelList_trackingNo,
    //                         parcel_type: vArrParcelData[i].parcelList_boxType + "",              // ID ของ ประเภทกล่อง ex. brown envelope, brown parcel box, seal bag, white envelope
    //                         parcel_box_size: vArrParcelData[i].parcelList_parcelSize + "",       // ID ของ ขนาดกล่อง ex. extra large size, small size
    //                         parcel_shipping_type: vArrParcelData[i].parcelList_sendType + "",    // ID ของประเภทการขนส่ง ex. standard, COD, EMS
    //                         delivery_company: vArrParcelData[i].parcelList_deliveryComp + "",    // ID ของบริษัทขนส่ง ex. DHL, Kerry, Thailand Post, CJ Logistic
    //                         parcel_status: vArrParcelData[i].parcelList_status
    //                     }
    //                 })
    //             },
    //         });
    //     }
    //     setReadyToCreate(false)
        
    // }

    // const [vArrParcelData, setArrParcelData] = useState<any>([]);
    // const [vReadyToCreate, setReadyToCreate] = useState<any>(false);
    const [vDupStat, setDupStat] = useState<any>(false);
    // const [vTotal, setTotal] = useState<any>(0);

    // console.log('vDupStat', vDupStat)

    // function addItemToArray(unitPropertyData:any, note:any, box_type:any, parcel_size:any, send_type:any, delivery_comp:any, status:any, receiver_name:any, track_no:any){
    //     // console.log('setDataToState 1 data', unitPropertyData)

    //     // console.log('*******************************')
    //     // console.log('unitPropertyData', unitPropertyData)
    //     // console.log('note', note)
    //     // console.log('box_type', box_type)
    //     // console.log('parcel_size', parcel_size)
    //     // console.log('send_type', send_type)
    //     // console.log('delivery_comp', delivery_comp)
    //     // console.log('status', status.toUpperCase())
    //     // console.log('receiver_name', receiver_name)

    //     vArrParcelData.push(
    //         {
    //             parcelIn_property: unitPropertyData.propertyID + "",
    //             parcelIn_unit: unitPropertyData.unitID + "",
    //             parcelIn_customerName: receiver_name + "",
    //             parcelIn_note: note + "",
    //             parcelIn_createDate: dayjs(),
    //             parcelIn_createBy: user_data.user_profile.id,
    //             parcelIn_inDate: dayjs(),
    //             parcelList_trackingNo: track_no,
    //             parcelList_boxType: box_type + "", 
    //             parcelList_parcelSize: parcel_size + "",
    //             parcelList_sendType: send_type + "",
    //             parcelList_deliveryComp: delivery_comp + "",
    //             parcelList_status: status.toUpperCase()
    //         }
    //     );
    //     setArrParcelData([vArrParcelData]);

    // }
    // console.log('vArrParcelData', vArrParcelData[0])
    // console.log('parcel in ID', vParcelInID)

    // console.log('user_profile ID ', vUserID)
    // console.log('unit ID ', vUnitID)
    // console.log('property ID ', vPropertyID)

    // console.log('k_userID', k_userID);
    // console.log('k_unitID', k_unitID);
    // console.log('k_propertyID', k_propertyID);

    // function sleep(ms: number) {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }
    
    // const [vArrParcel, setArrParcel] = useState<any>([]);  
    // const [vCount, setCount] = useState<any>(0);
    const successNotiImport = (stat:any, msg:any) => {
        notification.success({
            message: 'Success',
            description: msg,
            duration: 2,
        });
    };

    // const errorNotiImport = (stat:any, msg:any) => {
    //     notification.error({
    //         message: 'Error',
    //         description: 'Missing data on CSV file.',
    //         duration: 2,
    //     });
    // };

    const noticeOnScreen = () => {
        notification.warning({
            message: 'Warning',
            description: 'Duplicate data.',
            duration: 2,
        });
    };

    const [csvData, setCsvData] = useState([]);

    const handleCsvData = (data:any) => {
      // Handle the CSV data here, e.g., set it in state
      setCsvData(data);

      mutateParcelIn(data);
    };

    const mutateParcelIn = (item:any) => {
        // console.log('csvData in func', item)
        // const dataImport = item.slice(1);
        const dataImport = item
        // console.log('dataImport', dataImport);

        // for (let index = 0; index < dataImport.length; index++) {
        //     // console.log('dataImport', dataImport[index])
        //     let parcel_send_type_id:any;
        //     let parcel_box_type_id:any;
        //     let parcel_size_id:any;
        //     let deliver_company_id:any;
        //     let received_date:any;
        //     let prefix:any;

        //     // เอา ID ของ parcel_send_type, parcel_box_type, parcel_size, deliver_company
        //     if(dataImport[index].parcel_send_type !== ""){
        //         // parcel_send_type_id = dataImport[index].parcel_send_type.split("-");
        //         parcel_send_type_id = dataImport[index][6].split("-");
        //     }

        //     if(dataImport[index].parcel_box_type !== ""){
        //         // parcel_box_type_id = dataImport[index].parcel_box_type.split("-");
        //         parcel_box_type_id = dataImport[index][7].split("-");
        //     }

        //     if(dataImport[index].parcel_size !== ""){
        //         // parcel_size_id = dataImport[index].parcel_size.split("-");
        //         parcel_size_id = dataImport[index][8].split("-");
        //     }

        //     if(dataImport[index].deliver_company !== ""){
        //         // deliver_company_id = dataImport[index].deliver_company.split("-");
        //         deliver_company_id = dataImport[index][9].split("-");
        //     }

        //     if(dataImport[index].prefix !== ""){
        //         // prefix = dataImport[index].prefix.split("-");
        //         prefix = dataImport[index][3].split("-");
        //     }

        //     if(dataImport[index].receive_date !== ""){
        //         // received_date = dayjs(dataImport[index].receive_date, 'DD-MM-YYYY').toDate();
        //         received_date = dayjs(dataImport[index][11], 'DD-MM-YYYY').toDate();
        //     }

        //     // if(dataImport[index].unit_no === ""){
        //     if(dataImport[index][1] === ""){
        //         console.log('unit_no data is empty')
        //         return 'error'
        //     // }else if(dataImport[index].user_email === ""){
        //     }else if(dataImport[index][2] === ""){
        //         console.log('user_email data is empty')
        //         return 'error'
        //     // }else if(dataImport[index].track_no === ""){
        //     }else if(dataImport[index][5] === ""){
        //         console.log('track_no data is empty')
        //         return 'error'
        //     }else{
        //         setTimeout(() => {
        //             // เอา unit no ไปหาข้อมูล unit และ property
        //             // callAPI_findUnit(dataImport[index].unit_no).then(async x => {
        //             callAPI_findUnit(dataImport[index][1]).then(async x => {
        //                 if(x[0].unitID !== "nodata"){
        //                     // ส่งของไปเส้น like-systems/
        //                     createParcelIn2({
        //                         resource : 'like-systems/createParcelIn',
        //                         values : {
        //                             property : x[0].propertyID + "",
        //                             unit : x[0].unitID + "",
        //                             // customer_name: dataImport[index].receiver_name + "",
        //                             customer_name: dataImport[index][4] + "",
        //                             // note: dataImport[index].note + "",
        //                             note: dataImport[index][10] + "",
        //                             create_date : dayjs(),
        //                             createBy: user_data.user_profile.id,
        //                             // in_date : dataImport[index].receive_date, // เปลี่ยนไปใช้ date format แบบ yyyy-mm-dd
        //                             in_date : dataImport[index][11], // เปลี่ยนไปใช้ date format แบบ yyyy-mm-dd
        //                             // tracking_no: dataImport[index].track_no,
        //                             tracking_no: dataImport[index][5],
        //                             parcel_type: parcel_box_type_id[0] + "",           // ID ของ ประเภทกล่อง ex. brown envelope, brown parcel box, seal bag, white envelope
        //                             parcel_box_size: parcel_size_id[0] + "",           // ID ของ ขนาดกล่อง ex. extra large size, small size
        //                             parcel_shipping_type: parcel_send_type_id[0] + "", // ID ของประเภทการขนส่ง ex. standard, COD, EMS
        //                             delivery_company: deliver_company_id[0] + "",      // ID ของบริษัทขนส่ง ex. DHL, Kerry, Thailand Post, CJ Logistic
        //                             parcel_status: "WAITING",
        //                             // prefix : dataImport[index].prefix
        //                             prefix : dataImport[index][3]
        //                         },successNotification:false,errorNotification:false,
        //                     },{
        //                         onSuccess: async(data: any, link: any) =>{
        //                             // console.log('data onSuccess', data)
        //                             // console.log('parcel_in id >>> ', data.data.data.id) // id ของ parcel in
        //                             if(data.data.code == 400){
        //                                 setDupStat(true)
        //                                 noticeOnScreen();
        //                             }
        //                         },
        //                         onError(error, variables, context) {
        //                             console.log('error', error)
        //                             setDupStat(true)
        //                         },
        //                     })
        //                 }
 
        //                 parcel_data.push(
        //                     {
        //                         property : x[0].propertyID + "",
        //                         unit : x[0].unitID + "",
        //                         // customer_name: dataImport[index].receiver_name + "",
        //                         customer_name: dataImport[index][4] + "",
        //                         // note: dataImport[index].note + "",
        //                         note: dataImport[index][10] + "",
        //                         create_date : dayjs(),
        //                         createBy: user_data.user_profile.id,
        //                         // in_date : dataImport[index].receive_date, // เปลี่ยนไปใช้ date format แบบ yyyy-mm-dd
        //                         in_date : dataImport[index][11], // เปลี่ยนไปใช้ date format แบบ yyyy-mm-dd
        //                         // tracking_no: dataImport[index].track_no,
        //                         tracking_no: dataImport[index][5],
        //                         parcel_type: parcel_box_type_id[0] + "",           // ID ของ ประเภทกล่อง ex. brown envelope, brown parcel box, seal bag, white envelope
        //                         parcel_box_size: parcel_size_id[0] + "",           // ID ของ ขนาดกล่อง ex. extra large size, small size
        //                         parcel_shipping_type: parcel_send_type_id[0] + "", // ID ของประเภทการขนส่ง ex. standard, COD, EMS
        //                         delivery_company: deliver_company_id[0] + "",      // ID ของบริษัทขนส่ง ex. DHL, Kerry, Thailand Post, CJ Logistic
        //                         parcel_status: "WAITING",
        //                         // prefix : dataImport[index].prefix
        //                         prefix : dataImport[index][3]
        //                     }
        //                 );
        //                 setArrParcel(parcel_data)
        //                 vArrParcel.push(vArrParcel)

        //                 // await sleep(1000);
        //             })
        //         }, 500);
               
        //     }
        // }


        createParcelIn2({
            resource : 'like-systems/importParcel',
            values : {
                data : dataImport,
                createBy: user_data?.user_profile ? user_data?.user_profile?.id : null, 
            },successNotification:false,errorNotification:false,
        },{
            onSuccess: async(data: any, link: any) =>{
                console.log('data onSuccess', data)
                // console.log('parcel_in id >>> ', data.data.data.id) // id ของ parcel in
                if(data.data.code == 400){
                    setDupStat(true)
                    noticeOnScreen();
                }else{
                    successNotiImport(200, "Data Imported!")
                    // await sleep(2000);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
                
            },
            onError(error, variables, context) {
                console.log('error', error)
                setDupStat(true)
            },
        })
    }

    // console.log('csvData',csvData)

    // let parcel_data:any = []

    // const importProps = useImport<IParcelImport>({
    //     mapData: async (item) => {

            
    //         // console.log('item', item)

    //         let parcel_send_type_id:any;
    //         let parcel_box_type_id:any;
    //         let parcel_size_id:any;
    //         let deliver_company_id:any;
    //         let received_date:any;
    //         let prefix:any;

    //         // เอา ID ของ parcel_send_type, parcel_box_type, parcel_size, deliver_company
    //         if(item.parcel_send_type !== ""){
    //             parcel_send_type_id = item.parcel_send_type.split("-");
    //         }

    //         if(item.parcel_box_type !== ""){
    //             parcel_box_type_id = item.parcel_box_type.split("-");
    //         }

    //         if(item.parcel_size !== ""){
    //             parcel_size_id = item.parcel_size.split("-");
    //         }

    //         if(item.deliver_company !== ""){
    //             deliver_company_id = item.deliver_company.split("-");
    //         }

    //         if(item.prefix !== ""){
    //             prefix = item.prefix.split("-");
    //         }

    //         if(item.receive_date !== ""){
    //             received_date = dayjs(item.receive_date, 'DD-MM-YYYY').toDate();
    //         }

    //         if(item.unit_no === ""){
    //             console.log('unit_no data is empty')
    //             return 'error'
    //         }else if(item.user_email === ""){
    //             console.log('user_email data is empty')
    //             return 'error'
    //         }else if(item.track_no === ""){
    //             console.log('track_no data is empty')
    //             return 'error'
    //         }else{

    //             setTimeout(() => {
    //                 // เอา unit no ไปหาข้อมูล unit และ property
    //                 callAPI_findUnit(item.unit_no).then(async x => {
    //                     if(x[0].unitID !== "nodata"){

    //                         // let res_parcel_list = await axios.get(API_URL + "/parcel-lists?pagination[page]=1&pagination[pageSize]=10000&", { headers: { Authorization: AuthStr } });
        
    //                         // // console.log('res_parcel_list', res_parcel_list);
    //                         // console.log('res_parcel_list', res_parcel_list?.data?.data?.length);

    //                         // let run_no = res_parcel_list?.data?.data?.length + 1

    //                         // console.log('run_no', run_no)

    //                         // ส่งของไปเส้น like-systems/
    //                         createParcelIn2({
    //                             resource : 'like-systems/createParcelIn',
    //                             values : {
    //                                 property : x[0].propertyID + "",
    //                                 unit : x[0].unitID + "",
    //                                 customer_name: item.receiver_name + "",
    //                                 note: item.note + "",
    //                                 create_date : dayjs(),
    //                                 createBy: user_data.user_profile.id,
    //                                 // in_date : dayjs(),
    //                                 // in_date : received_date,
    //                                 in_date : item.receive_date, // เปลี่ยนไปใช้ date format แบบ yyyy-mm-dd
    //                                 tracking_no: item.track_no,
    //                                 parcel_type: parcel_box_type_id[0] + "",           // ID ของ ประเภทกล่อง ex. brown envelope, brown parcel box, seal bag, white envelope
    //                                 parcel_box_size: parcel_size_id[0] + "",           // ID ของ ขนาดกล่อง ex. extra large size, small size
    //                                 parcel_shipping_type: parcel_send_type_id[0] + "", // ID ของประเภทการขนส่ง ex. standard, COD, EMS
    //                                 delivery_company: deliver_company_id[0] + "",      // ID ของบริษัทขนส่ง ex. DHL, Kerry, Thailand Post, CJ Logistic
    //                                 // parcel_status: item.status.toUpperCase(),
    //                                 parcel_status: "WAITING",
    //                                 prefix : item.prefix
    //                             },successNotification:false,errorNotification:false,
    //                         },{
    //                             onSuccess: async(data: any, link: any) =>{
    //                                 // console.log('data onSuccess', data)
    //                                 // console.log('parcel_in id >>> ', data.data.data.id) // id ของ parcel in
    //                                 if(data.data.code == 400){
    //                                     setDupStat(true)
    //                                     noticeOnScreen();
    //                                 }
    //                             },
    //                             onError(error, variables, context) {
    //                                 console.log('error', error)
    //                                 setDupStat(true)
    //                             },
    //                         })
    //                     }
 
    //                     parcel_data.push(
    //                         {
    //                             property : x[0].propertyID + "",
    //                             unit : x[0].unitID + "",
    //                             customer_name: item.receiver_name + "",
    //                             note: item.note + "",
    //                             create_date : dayjs(),
    //                             createBy: user_data.user_profile.id,
    //                             in_date : item.receive_date, // เปลี่ยนไปใช้ date format แบบ yyyy-mm-dd
    //                             tracking_no: item.track_no,
    //                             parcel_type: parcel_box_type_id[0] + "",           // ID ของ ประเภทกล่อง ex. brown envelope, brown parcel box, seal bag, white envelope
    //                             parcel_box_size: parcel_size_id[0] + "",           // ID ของ ขนาดกล่อง ex. extra large size, small size
    //                             parcel_shipping_type: parcel_send_type_id[0] + "", // ID ของประเภทการขนส่ง ex. standard, COD, EMS
    //                             delivery_company: deliver_company_id[0] + "",      // ID ของบริษัทขนส่ง ex. DHL, Kerry, Thailand Post, CJ Logistic
    //                             parcel_status: "WAITING",
    //                             prefix : item.prefix
    //                         }
    //                     );
    //                     setArrParcel(parcel_data)
    //                     vArrParcel.push(vArrParcel)

    //                     // await sleep(1000);
    //                 })
    //             }, 500);

                
               
    //         }
    //     },
    //     onProgress(onProgressParams) {
    //         // console.log('onProgressParams',onProgressParams)
    //         // setTotal(onProgressParams.totalAmount)
    //     },
    //     async onFinish(results) {
    //         // console.log('results xxx', results)
    //         if(vTotal == vArrParcel.length){
    //             if(!vDupStat){
    //                 successNotiImport(200, "Data Imported!")
    //                 // await sleep(2000);
    //                 setTimeout(() => {
    //                     window.location.reload();
    //                 }, 2000);
    //             }
    //         }
            
    //     },
    //     // resourceName: 'parcel-ins'
    // });

    const [ vPropertyInput, setPropertyInput] = useState<any>();
    const [ selunit, setunit] = useState<any>();

    const [ vDate2, setDate2 ] = useState<any>("");


    const handleNewDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        // console.log(dayjs(dateString).format("YYYY-MM-DD"));
        // setDate2(dayjs(dateString));
        setDate2(dayjs(dateString).format("YYYY-MM-DD"));
        // setDate2(dateString);
    };

    // console.log('vDate2', vDate2)

    // cyberpunk2077
    // const Ref2 = localStorage.getItem("Ref2");
    // if(Ref2 == "1"){
    //     localStorage.setItem("Ref2", "0");
    //     window.location.reload();
    // }
    // const Ref2 = localStorage.getItem("Ref2");

    // const [count1, setCount1] = useState(0)
    // useEffect(() => {
    //     if (count1 < 3) {
    //         const interval = setInterval(() => {
    //             setCount1(prev => prev + 1);

    //             if(count1 === 2){
    //                 if(Ref2 == "1"){
    //                     localStorage.setItem("Ref2", "0");
    //                     window.location.reload();
    //                 }
    //             }

    //         }, 1000)

    //         return () => clearInterval(interval)
    //     }

    // }, [count1])

    useEffect(() => {
        if (!selunit && !vPropertyInput && !vDate2 && !Rc && !trackingNo && Identity_data) {
            getData()
        }
      }, [selunit, vPropertyInput, vDate2, Rc, trackingNo]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,Identity_data])
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    // console.log('count1', count1)

    // console.log('vPropertyInput', vPropertyInput)
    // console.log('selunit', selunit)

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        // filters: initF,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
        pagination:{
            pageSize: 200
        }
    });

    const { selectProps: selectUnit } = useSelect<IUnit>({
        resource    : "units",
        optionLabel : "unit_name",
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "property][id]",
                operator: "eq",
                value: data_property || vPropertyInput
            }
        ]
    });

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : undefined
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }
    };

    const onReset = () => {
        setPropertyInput(undefined)
        setunit(undefined)
        setDate2(undefined)
        setRc(undefined)
        setTrackingNo(undefined)
        // searchFormProps.form?.resetFields()
        // searchFormProps.form?.submit()
        formRef?.current?.resetFields();
    };

    return <>
        <List 
            title={t("parcel-in.list")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("parcel-in.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row>
                <Col span={24}>
                    <Form
                        // {...searchFormProps}
                        ref={formRef}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        {
                            data_property ? <></> :
                            <Form.Item name="property_name" >
                                <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '140px'}} allowClear
                                    onChange={value => { 
                                        // console.log(value);
                                        setPropertyInput(value);
                                    }} 
                                />
                            </Form.Item>
                        }

                        <Form.Item name="selUnit">
                            <Select 
                                {...selectUnit}
                                placeholder="Unit name"
                                size="middle"
                                allowClear
                                onClear={()=> setunit(undefined)}
                                onChange={(e) => setunit(e)}
                                style={{borderRadius: 5, width: '125px'}}
                            />
                        </Form.Item>

                        <Form.Item name="receivedDate">
                            <DatePicker 
                                onChange={(date, dateString) => handleNewDatePickerChange(date, dateString, 1)} 
                            />
                        </Form.Item>

                        <Form.Item name="running_code" style={{width:'115px'}}>
                            <Input placeholder="running code" onChange={(e:any) => setRc(e.target.value)} ></Input>
                        </Form.Item>

                        <Form.Item name="tracking_no" style={{width:'115px'}}>
                            <Input placeholder="tracking no." onChange={(e:any) => setTrackingNo(e.target.value)} ></Input>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={getData} htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>

                {/* <Col span={6}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right", width:'200px'}}
                    >
                        <Form.Item>
                            <Button style={{borderRadius:5}} icon={<DownloadOutlined />} >
                                <a style={{color:'#595959'}} href="https://lscmain.s3.ap-southeast-1.amazonaws.com/parcel_template_3_2ae9b12c7c.xlsx?updated_at=2023-01-06T04:57:43.759Z"> Download Template</a>
                            </Button> 

                            <CsvImport onCsvData={handleCsvData} />

                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Form>
                </Col> */}

                {/* <Button style={{borderRadius:5}} icon={<DownloadOutlined />} >
                    <a style={{color:'#595959'}} href="https://lscmain.s3.ap-southeast-1.amazonaws.com/parcel_template_3_2ae9b12c7c.xlsx?updated_at=2023-01-06T04:57:43.759Z"> Download Template</a>
                </Button> 

                <CsvImport onCsvData={handleCsvData} /> */}


            </Row>
            <Row style={{float:"right", marginBottom:'4px'}}>
                <Col span={4}></Col>
                <Col span={8}>
                    <Button style={{borderRadius:5}} icon={<DownloadOutlined />} >
                        <a style={{color:'#595959'}} href="https://lscmain.s3.ap-southeast-1.amazonaws.com/parcel_template_3_2ae9b12c7c.xlsx?updated_at=2023-01-06T04:57:43.759Z"> Download Template</a>
                    </Button> 
                </Col>

                <Col span={8}>
                    <CsvImport onCsvData={handleCsvData} />
                </Col>

                <Col span={4}>
                    <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                </Col>
            </Row>

            <Table 
                // {...tableProps}
                dataSource={dataParcelIn.length > 0 ? dataParcelIn : null}
                bordered
                size="middle"
                rowKey = "id"
                loading = {loadingParcelIn}
                onChange={handleTableChange}
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    // total:paginationLength.length,
                    // total:parcelInData.length,  
                    // total:tableQueryResult?.data?.total,  
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false,
                    current: metaPagination?.page || page,
                    pageSize: metaPagination?.pageSize || pageSizeChange,
                    total: metaPagination?.total,
                    // metaPagination?.pageCount
                    position:["bottomRight"]
                }}
            >
                {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/>      */}

                <Table.Column key={'[property][property_name]'} title="PROPERTY" width={300}
                    // sorter={(a:any, b:any) => a?.property ? a?.property?.property_name : "".localeCompare(b?.property ? b.property?.property_name : "")}
                    // sortDirections={['ascend', 'descend']}
                    sorter={(a:any, b:any) => {
                        if (a?.property?.property_name < b?.property?.property_name) {return -1}
                        if (a?.property?.property_name > b?.property?.property_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.property ? record?.property?.property_name : '-'}  />
                        </>
                    }}
                />

                <Table.Column key={'[unit][unit_name]'} title="UNIT" width={150}
                    sorter={(a:any, b:any) => a.unit?.unit_name.localeCompare(b.unit?.unit_name)}
                    sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record.unit ? record.unit.unit_name : '-'}  />
                        </>
                    }}
                />

                <Table.Column 
                    // dataIndex="rc" 
                    key={'[parcel_lists][running_code]'} 
                    title="RC"
                    width={200}
                    // sorter={(a:any, b:any) => a?.parcel_lists[0]?.running_code.toString().localeCompare(b?.parcel_lists[0]?.running_code.toString())}
                    sorter={(a:any, b:any) => a?.running_code.toString().localeCompare(b?.running_code.toString())}

                    // sorter={(a, b) =>
                    //     (a.parcel_lists[0]?.running_code).localeCompare(b.parcel_lists[0]?.running_code)
                    // }

                    // sorter={(a, b) => {
                    //     const runningCodeA = a.parcel_lists[0]?.running_code || '';
                    //     const runningCodeB = b.parcel_lists[0]?.running_code || '';
                    //     if (typeof runningCodeA === 'string' && typeof runningCodeB === 'string') {
                    //       return runningCodeA.localeCompare(runningCodeB);
                    //     }
                    //     return 0;
                    // }}

                    sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <TextField value={record ? record?.running_code ? record?.running_code : '-' : '-'}  />
                    }}
                    // render={(value:any,record:any)=>{
                    //     return <TextField value={record ? record?.parcel_lists ? record?.parcel_lists[0]?.running_code ? record?.parcel_lists[0]?.running_code : '-' : '-' : '-'}  />
                    // }}
                />

                <Table.Column /*key={'[parcel_lists]'}*/ title="TOTAL PARCEL" align="center" width={150}
                    // sorter={(a:any, b:any) => {
                    //     if (a?.data?.parcel_lists < b?.data?.parcel_lists) {return -1}
                    //     if (a?.data?.parcel_lists > b?.data?.parcel_lists) {return 1}
                    //     return (0)
                    // }}
                    render={(value:any,record:any)=>{
                        //console.log("sortt",record)
                        return <>
                            {
                                // parcelInData.length > 0 ? 
                                // parcelInData.map((data:any,index:any) => {
                                //     if(record.id === data.id){
                                //         return <TextField value={data?.parcel_lists ? data?.parcel_lists.length : '0'}  />
                                //     }
                                // }): "-"
                                record?.parcel_lists > 0 ? record?.parcel_lists : '-'
                            }
                        </>
                    }}
                />

                <Table.Column key={'[user_profile][first_name]'} title="FULL NAME" align="center" width={300}
                    sorter={(a:any, b:any) => a.user_profile ? a.user_profile.first_name : a.customer_name ? a.customer_name : "".localeCompare(b.user_profile ? b.user_profile.first_name : b.customer_name ? b.customer_name : "")}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        { 
                            let str2 = record?.customer_name ? record?.customer_name : ""
                            let str2Split = []
                            if(str2 !== ""){ str2Split = str2.split("-");}

                            return  <TextField value={record?.user_profile ? record?.user_profile? record?.user_profile?.first_name + " " + record?.user_profile?.last_name : str2Split[1] ? str2Split[1] : '-' : '-'} />
                        }
                    }}
                />

                <Table.Column key={'[in_date]'} title="RECEIVED DATE" align="center" width={200}
                    sorter={(a:any, b:any) => a?.in_date?.localeCompare(b?.in_date)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={record.in_date ? dayjs(record.in_date).format("YYYY-MM-DD HH:mm") : "-"}  />
                    }}
                />

                <Table.Column key={'[createBy][first_name]'} title="CREATE BY" align="center" width={200}
                    sorter={(a:any, b:any) => {
                        if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                        if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                        return (0)
                    }}

                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"} />
                    }}
                />

                <Table.Column key={'[createdAt]'} title="CREATE DATE" align="center" width={200}
                    sorter={(a:any, b:any) => a?.createdAt?.localeCompare(b?.createdAt)}
                    sortDirections={['ascend', 'descend']}
                    
                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={record?.createdAt ? dayjs(record.createdAt).format("YYYY-MM-DD HH:mm") : "-"}  />
                    }}
                />

                <Table.Column key={'[updateBy][first_name]'} title="UPDATE BY" align="center" width={200}
                    sorter={(a:any, b:any) => a.updateBy ? a.updateBy?.first_name : "".localeCompare(b.updateBy ? b.updateBy?.first_name : "")}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                    }}
                />

                <Table.Column key={'[updatedAt]'} title="LAST UPDATE" align="center" width={200}
                    sorter={(a:any, b:any) => a?.updatedAt?.localeCompare(b?.updatedAt)}
                    sortDirections={['ascend', 'descend']}
                    
                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={record?.updatedAt ? dayjs(record.updatedAt).format("YYYY-MM-DD HH:mm") : "-"}  />
                    }}
                />

                <Table.Column<IParcelBox>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed = "right"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                // disabled
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    </>
}
/* eslint-disable */
import { Badge, Breadcrumb, Button, Col, DatePicker,  EditButton, Form, Icons, Input, List, message, Modal,  Row,  Select,  Space, Table,  Tabs,  Tag,  TextField, useForm, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IParcelBox, IParcelList, IProperties, IUnit } from "interfaces";
// import Moment from 'moment';
import { useState, useEffect } from "react";
import { WarningOutlined, QuestionCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import moment from "moment";
import { TOKEN_KEY } from "../../constants";
import axios from "axios";
import { PORTAL_URL } from "../../constants";

let dataContent: any = undefined;
export const ParcelListList: React.FC<IResourceComponentsProps> = () => {

    const tabParcel = localStorage.getItem("keyTabParcel") || 'WAITING';

    const { Option } = Select;
    const { TabPane } = Tabs;
    const API_URL = useApiUrl();
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Parcel List");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [keepReturnLength, setKeepReturnLength] = useState<any>();
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const [Rc, setRc] = useState<any>(undefined);
    const [listData, setListdata] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(null);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [waitingBadgeNumber, setWaitingBadgeNumber] = useState<any>(null)
    const [receivedBadgeNumber, setReceivedBadgeNumber] = useState<any>(null)
    const [returnBadgeNumber, setReturnBadgeNumber] = useState<any>(null)
    const [allBadgeNumber, setAllBadgeNumber] = useState<any>(null)
    
    const { formProps } = useForm<any,HttpError,{}>() 

    // search input
    const [ vQRInput, setQRInput] = useState<any>();
    const [ vTrackingNo, setTrackingNo] = useState<any>();
    const [ vPropertyInput, setPropertyInput] = useState<any>();
    const [ selunit, setunit] = useState<any>();
    const [ vSelStat, setSelStat] = useState<any>(tabParcel || "WAITING");
    const [modal2Open, setModal2Open] = useState(false);
    const [vModalTrackingNo, setModalTrackingNo] = useState<any>('');
    
    const ed_point = "parcel-lists";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    // const [vTodayDate, setTodayDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [vTodayDate, setTodayDate] = useState(moment().format("YYYY-MM-DD"));

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        // filters: initF,
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const { selectProps: selectUnit } = useSelect<IUnit>({
        resource    : "units",
        optionLabel : "unit_name",
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "property][id]",
                operator: "eq",
                value: vPropertyInput
            }
        ]
    });

    const onReset = () => {
        setQRInput(undefined)
        setPropertyInput(undefined)
        setunit(undefined)
        setTrackingNo(undefined)
        setDate2(undefined)
        setSelStat(tabParcel)
        setRc(undefined)
        formProps.form?.resetFields()
        // formProps.form?.submit()
    };

    function onStatusChange(v:any){
        setSelStat(v);
        // formProps.form?.submit();
        localStorage.setItem("keyTabParcel", v)
        // localStorage.setItem("keyU",v)
        // localStorage.setItem("keyTabUserPortal", v)
    }

    function onOkSearchTrack(){
        // console.log('search search search')
        let trackingNo_UserID:any;
        trackingNo_UserID = vModalTrackingNo.split("/");
        localStorage.setItem("receiverID", trackingNo_UserID[1]);

        console.log('vModalTrackingNo', vModalTrackingNo)
        callAPI();
        setModal2Open(false)
    }

    let resultQuery1:any = [];
    // const _URL = "http://localhost:3000/parcel/parcel-lists/edit/"; // LOCAL
    // const _URL = "https://sit-lsc2.techscape.app/parcel/parcel-lists/edit/"; // SIT
    // const _URL = "https://uat-lsc.techscape.app/parcel/parcel-lists/edit/"; // UAT 
    const _URL = `${PORTAL_URL}/parcel/parcel-lists/edit/`;

    async function callAPI(){
        // http://192.168.1.39:1337/api/facility-time-slots/slot_time_chk?date=20/07/2022&property=2&unit=7&facility=4&max_user=4

        let trackingNo_UserID:any;
        trackingNo_UserID = vModalTrackingNo.split("/");
        // console.log('trackingNo_UserID', trackingNo_UserID)
        localStorage.setItem("receiverID", trackingNo_UserID[1]);

        // trackingNo_UserID[0] = tracking_no 
        // trackingNo_UserID[1] = user_id 

        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        // resultQuery1 = await axios.get(API_URL + "/like-systems/findParcelByTrack?tracking_no=" + vModalTrackingNo , { headers: { Authorization: AuthStr } });
        resultQuery1 = await axios.get(API_URL + "/like-systems/findParcelByTrack?tracking_no=" + trackingNo_UserID[0] , { headers: { Authorization: AuthStr } });

        // console.log('resultQuery1', resultQuery1)

        // window.location.replace(_URL + id);


        if(resultQuery1.data !== 404){
            console.log('resultQuery1', resultQuery1.data.id)
            window.location.replace(_URL + resultQuery1.data.id);

        }else if(resultQuery1.data == 404){
            // console.log('resultQuery1', resultQuery1)
            // console.log('not found')
            message.error('Not found!');
        }
    }

    function handleCloseModal(){
        // form.resetFields(["user_sub_types"])
        setModal2Open(false)
    }

    const [ vDate2, setDate2 ] = useState<any>("");
    const handleNewDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setDate2(dayjs(dateString).format("YYYY-MM-DD"));
    };

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : sorterOrder
            switch (sorter?.column?.key) {
                case '[received_date]':{
                    setTableSorter({
                        muitipleSort: `sort[0]=received_date:${order}&sort[1]=return_date:${order}`,
                    })
                    break;
                }
                case '[parcel_in][user_profile][first_name]':{
                    setTableSorter({
                        muitipleSort: `sort[0]=parcel_in.user_profile.first_name:${order}&sort[1]=parcel_in.customer_name:${order}`,
                    })
                    break;
                }
                case '[received_user][first_name]':{
                    setTableSorter({
                        muitipleSort: `sort[0]=received_user.].first_name:${order}&sort[1]=customer_name:${order}`,
                    })
                    break;
                }
                case '[count_day]':{
                    setTableSorter(undefined)
                    break;
                }
                default:{
                    setTableSorter({
                        title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                        order: order ,
                    })
                    break;
                }
            }
        }
        else{
            setTableSorter(undefined)
        }

        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSizeChange(pagination?.pageSize)
        }
    };
    
    const getData = async ()=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=parcel_in.user_profile.user_prefix&populate=parcel_in.createBy&populate=parcel_in.unit&populate=parcel_in.property&populate=parcel_in.in_date&populate=parcel_box_size&populate=delivery_company&populate=parcel_type&populate=returnBy&populate=received_user&populate=parcel_in.updateBy&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(vPropertyInput){
                apiEndPoint += `&filters[parcel_in][property][id][$eq]=${vPropertyInput}`
            }
            else if(prop_list){
                apiEndPoint += `&filters[parcel_in][property][id][$in]=${prop_list}`
            }
            else if(prop_id){
                apiEndPoint += `&filters[parcel_in][property][id][$eq]=${prop_id}`
            }
            if(selunit){
                apiEndPoint += `&filters[parcel_in][unit][id][$eq]=${selunit}`
            }
            if(vTrackingNo){
                apiEndPoint += `&filters[tracking_no][$contains]=${vTrackingNo}`
            }
            if(vDate2){
                apiEndPoint += `&filters[parcel_in][in_date][$contains]=${vDate2}`
            }
            if(Rc){
                apiEndPoint += `&filters[running_code][$contains]=${Rc}`
            }
            if(vSelStat){
                if(vSelStat == "ALL"){
                    apiEndPoint += `&filters[\$or][0][parcel_status][$eq]=WAITING`
                    apiEndPoint += `&filters[\$or][1][parcel_status][$eq]=RECEIVED`
                    apiEndPoint += `&filters[\$or][2][parcel_status][$eq]=RETURN`
                }
                else{
                    apiEndPoint += `&filters[parcel_status][$eq]=${vSelStat}`
                }
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else if(tableSorter?.muitipleSort){
                apiEndPoint += `&${tableSorter?.muitipleSort}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const resultList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let delivery_company = null
                        let parcel_box_size = null
                        let parcel_in = null
                        let parcel_type = null
                        let received_user = null
                        let returnBy = null
                        if(attributes?.delivery_company?.data){
                            delivery_company = {id: attributes?.delivery_company?.data?.id , ...attributes?.delivery_company.data.attributes}
                        }
                        if(attributes?.parcel_box_size?.data){
                            parcel_box_size = {id: attributes?.parcel_box_size?.data?.id , ...attributes?.parcel_box_size.data.attributes}
                        }
                        if(attributes?.parcel_in?.data){
                            try {
                                if(attributes?.parcel_in?.data?.attributes){
                                    const parcelInData = attributes?.parcel_in?.data
                                    let unit = null
                                    let property = null
                                    let createBy = null
                                    let updateBy = null
                                    let user_profile = null
                                    if(parcelInData?.attributes?.unit?.data){
                                        const unitData = parcelInData?.attributes?.unit?.data
                                        let property = null
                                        let unitType = null
                                        try {
                                            if(unitData?.attributes?.property?.data){
                                                const propertyData = unitData?.attributes?.property?.data
                                                let project = null
            
                                                if(propertyData?.attributes?.aqi_response?.data){
                                                    propertyData.attributes.aqi_response = propertyData?.attributes?.aqi_response?.data
                                                }
                                                if(propertyData?.attributes?.project?.data){
                                                    const projectData = propertyData?.attributes?.project?.data
                                                    let projectBrand = null
                                                    if(projectData?.attributes?.project_brand?.data){
                                                        const projectBrandData = projectData?.attributes?.project_brand?.data
                                                        projectBrand = {id: projectBrandData?.id , ...projectBrandData?.attributes}
                                                    }
                                                    project = {id: projectData?.id , ...projectData?.attributes}
                                                    project.project_brand = projectBrand
                                                }
                                                property = {id: propertyData?.id , ...propertyData?.attributes}
                                                property.project = project
                                            }
                                        } catch (error) {
                                            property = null
                                        }
                                        try {
                                            if(unitData?.attributes?.unit_type?.data){
                                                const unitTypeData = unitData?.attributes?.unit_type?.data
                                                unitType = {id: unitTypeData?.id , ...unitTypeData?.attributes}
                                            }
                                        } catch (error) {
                                            unitType = null
                                        }
                                        unit = {id: unitData?.id , ...unitData?.attributes}
                                        unit.property = property
                                        unit.unit_type = unitType
                                    }
                                    try {
                                        if(parcelInData?.attributes?.property?.data){
                                            const propertyData = parcelInData?.attributes?.property?.data
                                            property = {id: propertyData?.id , ...propertyData?.attributes}
                                        }
                                    } catch (error) {
                                        property = null
                                    }
                                    try {
                                        if(parcelInData?.attributes?.createBy?.data){
                                            const createByData = parcelInData?.attributes?.createBy?.data
                                            createBy = {id: createByData?.id , ...createByData?.attributes}
                                        }
                                    } catch (error) {
                                        createBy = null
                                    }
                                    try {
                                        if(parcelInData?.attributes?.updateBy?.data){
                                            const updateByData = parcelInData?.attributes?.updateBy?.data
                                            updateBy = {id: updateByData?.id , ...updateByData?.attributes}
                                        }
                                    } catch (error) {
                                        updateBy = null
                                    }
                                    try {
                                        if(parcelInData?.attributes?.user_profile?.data){
                                            const userProfileData = parcelInData?.attributes?.user_profile?.data
                                            let user_prefix = null
                                            try {
                                                if(userProfileData?.attributes?.user_prefix?.data){
                                                    const userPrefixData = userProfileData?.attributes?.user_prefix?.data
                                                    user_prefix = {id: userPrefixData?.id , ...userPrefixData?.attributes}
                                                }
                                            } catch (error) {
                                                user_prefix = null
                                            }
                                            user_profile = {id: userProfileData?.id , ...userProfileData?.attributes}
                                            user_profile.user_prefix = user_prefix
                                        }
                                    } catch (error) {
                                        user_profile = null
                                    }
                                    parcel_in = {id: parcelInData?.id , ...parcelInData.attributes}
                                    parcel_in.unit = unit
                                    parcel_in.property = property
                                    parcel_in.createBy = createBy
                                    parcel_in.updateBy = updateBy
                                    parcel_in.user_profile = user_profile
                                }
                            } catch (error) {
                                parcel_in = null
                            }
                        }
                        if(attributes?.parcel_type?.data){
                            parcel_type = {id: attributes?.parcel_type?.data?.id , ...attributes?.parcel_type.data.attributes}
                        }
                        if(attributes?.received_user?.data){
                            received_user = {id: attributes?.received_user?.data?.id , ...attributes?.received_user.data.attributes}
                        }
                        if(attributes?.returnBy?.data){
                            returnBy = {id: attributes?.returnBy?.data?.id , ...attributes?.returnBy.data.attributes}
                        }
                        const result = {id: item?.id , ...attributes}
                        result.delivery_company = delivery_company
                        result.parcel_box_size = parcel_box_size
                        result.parcel_in = parcel_in
                        result.parcel_type = parcel_type
                        result.received_user = received_user
                        result.returnBy = returnBy
                        return result
                    })
                    setListdata(resultList || [])
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    setLoadingTable(false)
                });
        } catch (error) {
            setLoadingTable(false)
        }
    }
    const getBadgeNumber = async (tabName:any)=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&pagination[pageSize]=${1}&pagination[page]=${1}`
            if(tabName == "ALL"){
                apiEndPoint += `&filters[\$or][0][parcel_status][$eq]=WAITING`
                apiEndPoint += `&filters[\$or][1][parcel_status][$eq]=RECEIVED`
                apiEndPoint += `&filters[\$or][2][parcel_status][$eq]=RETURN`
            }
            else{
                apiEndPoint += `&filters[parcel_status][$eq]=${tabName}`
            }
            if(prop_list){
                apiEndPoint += `&filters[parcel_in][property][id][$in]=${prop_list}`
            }
            else if(prop_id){
                apiEndPoint += `&filters[parcel_in][property][id][$eq]=${prop_id}`
            }
            apiEndPoint += `&sort[id]=desc`
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data
                    if(dataLoad?.meta?.pagination?.total){
                        const total = dataLoad?.meta?.pagination?.total > 0 ? dataLoad?.meta?.pagination?.total : null
                        switch (tabName.toUpperCase()) {
                            case 'WAITING':{
                                setWaitingBadgeNumber(total)
                                break;
                            }
                            case 'RECEIVED':{
                                setReceivedBadgeNumber(total)
                                break;
                            }
                            case 'RETURN':{
                                setReturnBadgeNumber(total)
                                break;
                            }
                            default:{
                                setAllBadgeNumber(total)
                                break;
                            }
                        }
                    }
                }).catch((e) => {
                    console.log(e)
                });
        } catch (error) {
        }
    }

    useEffect(() => {
        if (!vPropertyInput && !selunit && !vTrackingNo && !vDate2 && !Rc && Identity_data) {
            getData()
        }
      }, [vPropertyInput, selunit, vTrackingNo, vDate2, Rc]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,vSelStat,Identity_data])

    useEffect(() => {
        if(Identity_data){
            getBadgeNumber('WAITING')
            getBadgeNumber('RECEIVED')
            getBadgeNumber('RETURN')
            getBadgeNumber('ALL')
        }
    }, [Identity_data])

    return <>
        <List 
            title={t("parcel-list.list")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("parcel-list.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            
            <Row>
                <Col span={24}>
                    <Form
                        {...formProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >

                        <Form.Item name="tracking_no" >
                            <Input 
                                placeholder="Tracking no." 
                                value={vTrackingNo}
                                allowClear
                                onChange={e => {
                                    if(e.target.value.length > 0){
                                        setTrackingNo(e.target.value)
                                    }else{
                                        setTrackingNo(undefined)
                                    }
                                }}
                                // type="submit"
                                style={{borderRadius: 5, width: '230px'}}
                            />
                        </Form.Item>

                        <Form.Item name="property_name" >
                            <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '180px'}} allowClear
                                onChange={value => { 
                                    // console.log(value);
                                    setPropertyInput(value);
                                }} 
                            />
                        </Form.Item>

                        <Form.Item name="selUnit">
                            <Select 
                                {...selectUnit}
                                placeholder="Unit name"
                                size="middle"
                                allowClear
                                onClear={()=> setunit(undefined)}
                                onChange={(e) => setunit(e)}
                                style={{borderRadius: 5, width: '130px'}}
                            />
                        </Form.Item>

                        <Form.Item name="receivedDate">
                            <DatePicker 
                                placeholder="Received Date"
                                onChange={(date, dateString) => handleNewDatePickerChange(date, dateString, 1)} 
                            />
                        </Form.Item>

                        <Form.Item name="running_code" style={{width:'120px'}}>
                            <Input placeholder="running code" onChange={(e:any) => setRc(e.target.value)} ></Input>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={getData} htmlType="button" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="reset" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>

            <Row>
                <Col span={21}>
                    <Form
                        {...formProps}
                    >
                        <Form.Item name="parcel_status" >
                            <Tabs 
                                defaultActiveKey={`${tabParcel}`}
                                style={{ marginTop: 10, marginBottom: 10 }} 
                                onChange={(e) => {
                                    onStatusChange(e)
                                    // formProps?.form?.submit();
                                }} 
                            >
                                <TabPane key="WAITING"
                                    tab={
                                        <span>
                                            {"WAITING"}
                                            <Badge 
                                                count={waitingBadgeNumber ? waitingBadgeNumber : 0}  
                                                style={{ backgroundColor: '#dddddd',marginLeft: "5px" }}
                                            />
                                        </span>
                                    }
                                ></TabPane>
                                <TabPane key="RECEIVED"
                                    tab={
                                        <span>
                                            {"RECEIVED"}
                                            <Badge 
                                                count={receivedBadgeNumber ? receivedBadgeNumber : 0} 
                                                style={{ backgroundColor: '#dddddd',marginLeft: "5px" }}
                                            />
                                        </span>
                                    }
                                ></TabPane>
                                <TabPane key="RETURN"
                                    tab={
                                        <span>
                                            {"RETURN"}
                                            <Badge 
                                                count={returnBadgeNumber ? returnBadgeNumber : 0}
                                                style={{ backgroundColor: '#dddddd',marginLeft: "5px" }}
                                            />
                                        </span>
                                    }
                                ></TabPane>
                                <TabPane key="ALL"
                                    tab={
                                        <span>
                                            {"ALL"}
                                            <Badge 
                                                count={allBadgeNumber ? allBadgeNumber : 0} 
                                                style={{ backgroundColor: '#dddddd',marginLeft: "5px" }}
                                            />
                                        </span>
                                    }
                                ></TabPane>
                            </Tabs>
                        </Form.Item>
                    </Form> 
                </Col>
                
                <Col span={3}>
                    <Button htmlType="button" type="primary" style={{borderRadius: "5px", width:'100%'}} onClick={() => setModal2Open(true)}>
                        <Icons.QrcodeOutlined /> สแกนจ่ายพัสดุ
                    </Button>
                </Col>
            </Row>

            <Modal
                // title="Search Parcel"
                centered
                visible={modal2Open}
                width={950}
                // onOk={() => setModal2Open(false)}
                // onOk={() => onOkSearchTrack()}
                // onCancel={() => setModal2Open(false)}
                onCancel={() => handleCloseModal()}
                footer={[
                    // <Button key="back" onClick={handleCancel}>
                    //   Return
                    // </Button>,
                    <Button key="submit" type="primary" 
                        // loading={loading} 
                        onClick={() => onOkSearchTrack()}>
                        Search
                    </Button>,
                    // <Button
                    //   key="link"
                    //   href="https://google.com"
                    //   type="primary"
                    //   loading={loading}
                    //   onClick={handleOk}
                    // >
                    //   Search on Google
                    // </Button>,
                    ]}
            >
                <div style={{paddingTop:'2%'}}></div>
                <Row>
                    <Col span={3}>  <div style={{float:'right'}}>Tracking number</div> </Col>
                    <Col span={1}></Col>
                    <Col span={18}>
                        <Form>
                            <Form.Item name="input_modal">
                                {/* <Input autoFocus></Input> */}
                                {/* <Input 
                                    autoFocus 
                                    onChange={(e) => setModalTrackingNo(e.target.value)} 
                                /> */}
                                <Input
                                    autoFocus
                                    onChange={(e) => setModalTrackingNo(e.target.value)}
                                    onKeyDown={(e)=> e.keyCode == 13 ? onOkSearchTrack() : ''}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </Modal>

            <Table
                dataSource={listData}
                onChange={handleTableChange}
                loading = {loadingTable}
                bordered
                size="middle"
                rowKey = "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    hideOnSinglePage: false,
                    current: metaPagination?.page || page,
                    pageSize: metaPagination?.pageSize || pageSizeChange,
                    total: metaPagination?.total,
                    // metaPagination?.pageCount
                    position:["bottomRight"]
                }}
                scroll={{x: 1300}}
            >
                <Table.Column key={'[parcel_in][property][property_name]'} title="PROPERTY" width={300}
                    sorter={(a:any, b:any) => a.parcel_in.property.property_name.localeCompare(b.parcel_in.property.property_name)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={record?.parcel_in && record?.parcel_in?.property ? record?.parcel_in?.property?.property_name : '-'}  />
                    }}
                />

                <Table.Column key={'[parcel_in][unit][unit_name]'} title="UNIT" width={150}
                    sorter={(a:any, b:any) => a.parcel_in.unit.unit_name.localeCompare(b.parcel_in.unit.unit_name)}
                    sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <TextField value={record?.parcel_in && record?.parcel_in?.unit ? record?.parcel_in?.unit?.unit_name : '-'}  />
                    }}
                />

                <Table.Column key={'[parcel_in][in_date]'} title="DATE IN" align="center" width={200}
                    sorter={(a:any, b:any) => a.parcel_in.in_date.localeCompare(b.parcel_in.in_date)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={dayjs(record?.parcel_in?.in_date).format("YYYY-MM-DD HH:mm")}  />
                    }}
                />

                <Table.Column key={'[received_date]'}title="DATE OUT" align="center" width={200}
                    sorter={(a:any, b:any) => a.received_date ? a.received_date : a.return_date ? a.return_date : "".localeCompare(b.received_date ? b.received_date : b.return_date ? b.return_date : "")}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={(record?.received_date || record?.return_date) ? dayjs(record?.received_date ? record?.received_date : record?.return_date).format("YYYY-MM-DD HH:mm") : '-'}  />
                    }}
                />

                <Table.Column key={'[parcel_type][title]'} title="PARCEL TYPE" align="center" width={200}
                    sorter={(a:any, b:any) => a?.parcel_type?.title.localeCompare(b?.parcel_type?.title)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={record?.parcel_type ? record?.parcel_type?.title : '-'} />
                    }}
                />

                <Table.Column key={'[parcel_box_size][title]'} title="SIZE" align="center" width={50}
                    sorter={(a:any, b:any) => a?.parcel_box_size?.title.localeCompare(b?.parcel_box_size?.title)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={record?.parcel_box_size ? record?.parcel_box_size?.title : '-'}  />
                    }}
                />

                <Table.Column key={'[tracking_no]'} dataIndex="tracking_no" title="TRACKING NO." align="center" width={150}
                    sorter={(a:any, b:any) => a?.tracking_no?.localeCompare(b?.tracking_no)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={value ? value : '-'}  />
                    }}
                />

                <Table.Column key={'[running_code]'} title="RC" width={200}
                    // sorter={(a:any, b:any) => a.running_code ? a.running_code : "".localeCompare(b.running_code ? b.running_code : "")}
                    sorter={(a:any, b:any) => a?.running_code?.toString().localeCompare(b?.running_code?.toString())}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={record?.running_code && record?.running_code !== "" ? record?.running_code : '-'}  />
                    }}
                />

                <Table.Column key={'[delivery_company][title]'} title="DELIVERY COMPANY" align="center" width={300}
                    sorter={(a:any, b:any) => a?.delivery_company?.title.localeCompare(b?.delivery_company?.title)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={record?.delivery_company ? record?.delivery_company?.title : '-'} />
                    }}
                />

                <Table.Column key={'[parcel_status]'} title="STATUS" align="center" width={100}
                    sorter={(a:any, b:any) => a.parcel_status.localeCompare(b.parcel_status)}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField 
                                value={record?.parcel_status ? 
                                    record?.parcel_status == "WAITING" ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} ><TextField style={{color:"#1D1D1D"}} value={"พัสดุรอรับ"}  /></Tag> :
                                    record?.parcel_status == "RECEIVED" ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#DCFDD4"} ><TextField style={{color:"#1D1929"}} value={"พัสดุรับแล้ว"}  /></Tag> :
                                    record?.parcel_status == "RETURN" && <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FDD4D4"}><TextField style={{color:"#AC1616"}} value={"พัสดุส่งกลับ"}  /></Tag>
                                : '-'}  
                            />
                    }}
                />

                <Table.Column key={'[note]'} title="NOTE" align="center" width={150}
                    sorter={(a:any, b:any) => a?.note ? a?.note: "".localeCompare(b?.note ? b?.note : "")}
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={record?.note && record?.note !== '' ? record?.note : '-'}  />
                    }}
                />

                <Table.Column key={'[parcel_in][user_profile][first_name]'} title="RECEIVE TO" align="center" width={150}
                    sorter={(a:any, b:any) => 0
                        // a.parcel_in?.user_profile ? a.parcel_in.user_profile.first_name : a.parcel_in.customer_name.localeCompare(b.parcel_in.user_profile ? b.parcel_in.user_profile.first_name : b.parcel_in.customer_name)
                    }
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        { 
                            let str2 = record?.parcel_in ? record?.parcel_in?.customer_name !== undefined ? record?.parcel_in?.customer_name : "" : "";
                            let str2Split = [];
                            let strRemoveID = [];

                            if(str2 !== null){
                                str2Split = str2?.split(" ");
                                strRemoveID = str2Split[0]?.split("-");
                            }

                            // str2Split[1] !== null ? str2Split[1] !== undefined ?  str2Split[1] : str2 : '-'

                            return  <TextField value={record?.parcel_in ? record?.parcel_in?.user_profile ? record?.parcel_in?.user_profile?.first_name + " " + record?.parcel_in?.user_profile?.last_name : str2Split[1] ? str2Split[1] : '-' : '-'} />
                        }
                    }}
                />

                <Table.Column key={'[received_user][first_name]'} title="RECEIVE BY" align="center" width={150}
                    sorter={(a:any, b:any) => 0
                        // a.received_user ? a.received_user.first_name : a.customer_name ? a.customer_name : "".localeCompare(b.received_user ? b.received_user.first_name : b.customer_name ? b.customer_name : "")
                    }
                    sortDirections={['ascend', 'descend']}

                    render={(value:any,record:any)=>{
                        return <TextField value={record?.received_user && record?.received_user !== '' ? record?.received_user?.first_name + " " + value?.last_name : record?.received_user?.customer_name && record?.customer_name !== '' ? record?.customer_name : '-'}  />
                    }}
                />

                <Table.Column key={'[count_day]'} dataIndex="received_date" title="COUNT DAYS" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.parcel_in?.in_date < b?.parcel_in?.in_date) {return -1}
                        if (a?.parcel_in?.in_date > b?.parcel_in?.in_date) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                // 1. ถ้า received_date ใน parcel_list ไม่ใช่ null ให้เอามาคิดแทน moment(vTodayDate)
                                record.parcel_in ? record.parcel_in.in_date ? record.received_date ?
                                            moment(record.received_date).diff(moment(record.parcel_in.in_date).format("YYYY-MM-DD"), "days")
                                        :
                                            moment(vTodayDate).diff(moment(record.parcel_in.in_date).format("YYYY-MM-DD"), "days")
                                    :
                                        <>-</>
                                :
                                <>-</>
                            }
                        </>
                    }}
                />

                <Table.Column key={'[parcel_in][createBy][first_name]'} title="CREATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => a?.parcel_in?.createBy?.first_name.localeCompare(b?.parcel_in?.createBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.parcel_in?.createBy ? record?.parcel_in?.createBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column key={'[createdAt]'} title="CREATE DATE" align="center" width={100}
                 sorter={(a:any, b:any) => {
                    if (a.createdAt < b.createdAt) {return -1}
                    if (a.createdAt > b.createdAt) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.createdAt ?
                                    <TextField value={moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />

                <Table.Column key={'[parcel_in][updateBy][first_name]'} title="UPDATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => a?.parcel_in?.updateBy?.first_name.localeCompare(b?.parcel_in?.updateBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.parcel_in?.updateBy? record?.parcel_in?.updateBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column key={'[updatedAt]'} title="LAST UPDATE" align="center" width={100}
                 sorter={(a:any, b:any) => {
                    if (a.updatedAt < b.updatedAt) {return -1}
                    if (a.updatedAt > b.updatedAt) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.updatedAt ?
                                    <TextField value={moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />

                <Table.Column<IParcelBox>
                    width={80}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed="right"
                    render={(_, record) => (
                        <Space>
                            {/* <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            >รายละเอียด</ShowButton> */}

                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                icon={<EyeOutlined />}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    </>
}
import { Authenticated, Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  Icons,
  Icon,
  CreateButton,
  //LoginPage,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import "styles/antd.less";
import "styles/App.css";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import { authProvider, axiosInstance } from "./authProvider";

import { API_URL } from "./constants";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { LoginPage } from "pages/login";
import { useTranslation } from "react-i18next";
import {
  UserList,
  UserProfileCreate,
  UserProfileEdit,
  UserProfileShow,
} from "pages/users";
import { PostCreate, PostEdit, PostList, PostShow } from "pages/posts";
import {
  PostTypeCreate,
  PostTypeEdit,
  PostTypeList,
  PostTypeShow,
} from "pages/post_types";
import {
  ProjectinfoCreate,
  ProjectinfoEdit,
  ProjectinfoList,
  ProjectinfoShow,
} from "pages/project_info";
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
  ProjectShow,
} from "pages/projects";
import { UnitCreate, UnitEdit, UnitList, UnitShow } from "pages/unit";
import {
  PropertiesCreate,
  PropertiesEdit,
  PropertiesList,
  PropertiesShow,
} from "pages/properties";
import {
  UserhunitCreate,
  UserhunitEdit,
  UserhunitList,
} from "pages/project-members";
import {
  PPtypesCreate,
  PPtypesEdit,
  PPtypesList,
  PPtypesShow,
} from "pages/pp_type";
import { ContenSeqList, ContenSeqList2 } from "pages/content_seq";
import { CouponList, CouponCreate, CouponShow, CouponEdit } from "pages/coupon";

import {HeartOutlined,QuestionCircleOutlined,DollarOutlined,GiftOutlined,FileOutlined,UserOutlined,FileDoneOutlined,SnippetsOutlined,OrderedListOutlined,ScheduleOutlined,BankOutlined,ApartmentOutlined,BookOutlined,SafetyCertificateOutlined,CarOutlined,IssuesCloseOutlined,BellOutlined,EnvironmentOutlined,CalendarOutlined,SettingOutlined,SmileOutlined} from "@ant-design/icons";
import {
  AppointmCreate,
  AppointmEdit,
  AppointmList,
  AppointmShow,
} from "pages/appointments";
import {
  AppointmTypeCreate,
  AppointmTypeEdit,
  AppointmTypeList,
  AppointmTypeShow,
} from "pages/appointments_type";
import {
  PhoneBooksCreate,
  PhoneBooksEdit,
  PhoneBooksList,
} from "pages/phone_book";
import { PhoneBooksTypeEdit, PhoneBooksTypeList } from "pages/phone_book_type";
import {
  PhoneBookSubTypeCreate,
  PhoneBookSubTypeEdit,
  PhoneBookSubTypeList,
} from "pages/phone_book_sub_type";
import { PhoneBooksShow } from "pages/phone_book/show";
import { PolicyCreate, PolicyEdit, PolicyList } from "pages/policy";
import { VehicleCreate, VehicleEdit, VehicleList } from "pages/vichicle";
import {
  VehicleBCreate,
  VehicleBEdit,
  VehicleBList,
} from "pages/vichicle-brand";
import {
  VehicleCCreate,
  VehicleCEdit,
  VehicleCList,
} from "pages/vichicle-color";
import {
  VehicleTCreate,
  VehicleTEdit,
  VehicleTList,
} from "pages/vichicle-type";
import {
  VehicleMCreate,
  VehicleMEdit,
  VehicleMList,
} from "pages/vichicle-model";
import { UnitTypeCreate, UnitTypeEdit, UnitTypeList } from "pages/unit-type";
import { TermCreate, TermEdit, TermList } from "pages/term";
import { PhoneBooksTypeCreate } from "pages/phone_book_type/create";
import {
  StaffHasPropCreate,
  StaffHasPropEdit,
  StaffHasPropList,
} from "pages/staff_has_properties";
import {
  NotificationCreate,
  NotificationEdit,
  NotificationList,
} from "pages/notification";
import {
  FacilitiesTypeCreate,
  FacilitiesTypeEdit,
  FacilitiesTypeList,
  FacilitiesTypeShow,
} from "pages/facilities_type";
import {
  FacilitiesCreate,
  FacilitiesEdit,
  FacilitiesList,
  FacilitiesShow,
} from "pages/facilities";
import {
  FacilitiesCondiCreate,
  FacilitiesCondiEdit,
  FacilitiesCondiList,
} from "pages/facilities_condition";
import {
  FacilitiesSubTypeCreate,
  FacilitiesSubTypeEdit,
  FacilitiesSubTypeList,
} from "pages/facility_sub_type";
import {
  FacilitiesBanCreate,
  FacilitiesBanEdit,
  FacilitiesBanList,
  FacilitiesBanShow,
} from "pages/facility_ban";
import {
  FacilitiesBKCreate,
  FacilitiesBKEdit,
  FacilitiesBKList,
  FacilitiesBKShow,
} from "pages/facility_booking";
import {
  MenuManageCreate,
  MenuManageEdit,
  MenuManageList,
} from "pages/menu_management";
import { CalendarList } from "pages/calendar";
import { CompanyCreate, CompanyEdit, CompanyList } from "pages/company_setup";
import { RoleCreate, RoleEdit, RoleList } from "pages/role_management";
import {
  ContentPostPromotionCreate,
  ContentPostPromotionEdit,
  ContentPostPromotionList,
  ContentPostPromotionShow,
  ContentPostPromotionShowUNTOKEN,
} from "pages/contentpost_promotion";
import {
  ContentPostActivityEventCreate,
  ContentPostActivityEventEdit,
  ContentPostActivityEventList,
  ContentPostActivityEventShow,
} from "pages/contentpost_activity_event";
import {
  ContentPostNewsCreate,
  ContentPostNewsEdit,
  ContentPostNewsList,
  ContentPostNewsShow,
} from "pages/contentpost_news";
import {
  ContentPostBlogCreate,
  ContentPostBlogEdit,
  ContentPostBlogList,
  ContentPostBlogShow,
} from "pages/contentpost_blog";
import {
  ContentPostAnnounceList,
  ContentPostAnnouncementCreate,
  ContentPostAnnouncementEdit,
  ContentPostAnnouncementShow,
} from "pages/contentpost_announcement";
import {
  ContentPostPrivilegeCreate,
  ContentPostPrivilegeEdit,
  ContentPostPrivilegeList,
  ContentPostPrivilegeShow,
} from "pages/contentpost_privilege";
import {
  UserManageCreate,
  UserManageEdit,
  UserManageList,
  UserManageShow,
} from "pages/users-portal";
import {
  ProjectBrandCreate,
  ProjectBrandEdit,
  ProjectBrandList,
} from "pages/project-brand";
import {
  BannerMgnCreate,
  BannerMgnEdit,
  BannerMgnList,
} from "pages/banner_management";
import {
  PopupMgnCreate,
  PopupMgnEdit,
  PopupMgnList,
} from "pages/popup_management";
import {
  CouponGroupCreate,
  CouponGroupEdit,
  CouponGroupList,
  CouponGroupShow,
} from "pages/coupon_group";
import {
  ContactCenterCreate,
  ContactCenterEdit,
  ContactCenterList,
} from "pages/contact_center";
import {
  ParcelTypeCreate,
  ParcelTypeEdit,
  ParcelTypeList,
} from "pages/parcel_type";
import {
  ParcelBoxCreate,
  ParcelBoxEdit,
  ParcelBoxList,
} from "pages/parcel_box";
import {
  ParcelShippingCreate,
  ParcelShippingEdit,
  ParcelShippingList,
} from "pages/parcel_shipping_type";
import {
  DeliveryCompCreate,
  DeliveryCompEdit,
  DeliveryCompList,
} from "pages/delivery_company";
import { ParcelInCreate, ParcelInEdit, ParcelInList } from "pages/parcel_in";
import { ParcelListEdit, ParcelListList } from "pages/parcel_list";
import {
  FaciiltyGenCheckInCreate,
  FaciiltyGenCheckInEdit,
  FaciiltyGenCheckInList,
  FaciiltyGenCheckInShow,
} from "pages/faciilty_gen_checkin";
import { FacilityWaitlist } from "pages/facility_waitlist";
import {
  MgnNewsEvent,
  MgnNewsEventEdit,
  MgnNewsEventCreate,
} from "pages/mgn_news_event";
import { DocTypeList } from "pages/document_type";
import { DocSubTypeList } from "pages/document_sub_type";
import { DocList, DocumentCreate } from "pages/document";
import { ConfigPaymentList } from "pages/config_payment";
import { BillingInvoiceList } from "pages/billing_invoice";
import { ReceiptList } from "pages/receipt";
import { ReceiptCancelList } from "pages/receipt_cancel";
import { JuristicWorkingDayList } from "pages/juristic_working_day";
import { PaymentNotiList } from "pages/payment_noti";
import { BillingpaymentList } from "pages/billing_payment";
import { StorePermission } from "components/role_permission";
import { SuggestionList } from "pages/suggestion";
import { ZoneList } from "pages/zone";
import { MjcTermConditionList } from "pages/mjc_term_condition";
import { MjcNotiMsgList } from "pages/mjc_noti_message";
import { HcuSetup } from "pages/hcu_setup";
import { HcuNotiMsgCreate, HcuNotiMsgEdit } from "pages/hcu_setup/notification";
import { HcuDaysCreate } from "pages/hcu_setup/setup_days/create";
import { HcuTermCreate, HcuTermEdit } from "pages/hcu_setup/term_and_condition";
import { HcuDaysEdit } from "pages/hcu_setup/setup_days";
import {
  HcutempMastercreate,
  HcutempMasteredit,
} from "pages/hcu_setup/template_master";
import {
  HcuAssignCreate,
  HcuAssignEdit,
  HcuAssignList,
} from "pages/hcu_setup/assign_inspec";
import { HcuMaster } from "pages/hcu_master";
import { HcuZoneCreate, HcuZoneEdit, HcuZoneList } from "pages/hcu_master/zone";
import { HcuStructureCreate } from "pages/hcu_master/structure/create";
import { HcuStructureEdit } from "pages/hcu_master/structure";
import {
  HcuChecklistCreate,
  HcuChecklistEdit,
} from "pages/hcu_master/checklist";
import { HcuRepairList } from "pages/hcu_job";
import { MjcNewJob, MjcNewJobEdit } from "pages/hcu_job/new_job";
import { MjcWaiting, MjcWaitingEdit } from "pages/hcu_job/waiting";
import { MjcInProgress, MjcInProgressEdit } from "pages/hcu_job/in_progress";
import { MjcDone, MjcDoneEdit } from "pages/hcu_job/done";
import { HcuRepairInspecList } from "pages/hcu_job_inspec";
import {
  MjcInspecTodayJobEdit,
  MjcInspecTodayJobList,
} from "pages/hcu_job_inspec/today_job";
import {
  MjcInspecNewJobEdit,
  MjcInspecNewJobList,
} from "pages/hcu_job_inspec/new_job";
import {
  MjcInspecInprogressEdit,
  MjcInspecInprogressJobList,
} from "pages/hcu_job_inspec/in_progress";
import {
  MjcInspecDoneJobEdit,
  MjcInspecDoneJobList,
} from "pages/hcu_job_inspec/done_job";
// import { MjcInspecEditChecklist } from "pages/hcu_job_inspec/edit_checklist";
import { HcuEmailGroupEdit, HcuEmailGroupList } from "pages/hcu_setup/email_group";
import { HcuEmailGroupCreate } from "pages/hcu_setup/email_group/create";
import { Repair } from "pages/repair_setup";
import { RepairTermCreate } from "pages/repair_setup/term_and_condition/create";
import { RepairTermEdit } from "pages/repair_setup/term_and_condition/edit";
import { RepairAssignCreate, RepairAssignEdit } from "pages/repair_setup/assign_technician";
import { RepairEmailGroupCreate } from "pages/repair_setup/email_group/create";
import { RepairEmailGroupEdit } from "pages/repair_setup/email_group";
import { RepairMaster } from "pages/repair_master";
import { RepairZoneCreate, RepairZoneEdit, RepairZoneList } from "pages/repair_master/repair_zone";
import { MjcInspecEditChecklistt } from "pages/hcu_job_inspec/edit_checklist";
import { RepairCategoryAndDetailList, RepairCategoryCreate, RepairCategoryEdit } from "pages/repair_master/category";
import { RepairDetailCreate, RepairDetailEdit } from "pages/repair_master/detail";
import listnull from "pages/page_null/list";
import { RepairMdpc } from "pages/repair_setup_mdpc";
import { RepairMdpcTermCreate, RepairMdpcTermEdit } from "pages/repair_setup_mdpc/term_and_condition";
import { RepairMdpcAssignCreate, RepairMdpcAssignEdit } from "pages/repair_setup_mdpc/assign_technician";
import { RepairMdpcEmailGroupCreate, RepairMdpcEmailGroupEdit } from "pages/repair_setup_mdpc/email_group";
import { RepairMajorAdmin } from "pages/repairlist_major_admin";
import { MjcRepairNewJobCreate, MjcRepairNewJobEdit } from "pages/repairlist_major_admin/new_job";
import { RepairMakeAppointment } from "pages/repairlist_major_admin/make_an_appointment";
import { MjcRepairWaitAppointmentJobEdit } from "pages/repairlist_major_admin/waiting_appointment_job";
import { RepairMajorInspec } from "pages/repairlist_major_inspec";
import { RepairSendToCustApprove } from "pages/repairlist_major_inspec/send_to_cust_approve";
import { MjcRepairInspecJobEdit } from "pages/repairlist_major_inspec/edit_job";
import { RepairMajorHod } from "pages/repairlist_major_hod";
import { MjcRepairTodayJobEdit } from "pages/repairlist_major_admin/today_job";
import { MjcRepairInprogressJobEdit } from "pages/repairlist_major_admin/inprogress_job";
import { MjcRepairWaitApproveJobEdit } from "pages/repairlist_major_admin/waiting_approv_job";
import { MjcRepairDoneJobEdit } from "pages/repairlist_major_admin/done_job";
import { RepairHodTodayJobList } from "pages/repairlist_major_hod/today_job";
import { RepairHodJobList } from "pages/repairlist_major_hod/job_list";
import { RepairHodInprogressJobList } from "pages/repairlist_major_hod/inprogress_job";
import { RepairHodDoneJobList } from "pages/repairlist_major_hod/done_job";
import { RepairHodWaitApproveJobList } from "pages/repairlist_major_hod/wait_approve_job";
import { MjcRepairHodViewJob } from "pages/repairlist_major_hod/view_job";
import { MjcRepairHodRealViewJob } from "pages/repairlist_major_hod/real_view_job";
import { MjcRepairWaitCustApproveJobEdit, MjcRepairWaitingCustApproveJob } from "pages/repairlist_major_admin/wait_cust_approve";
import { RepairSendToCustApproveAdmin } from "pages/repairlist_major_admin/send_to_cust_approve";
import { RepairMdpcAdmin } from "pages/repairlist_mdpc_admin";
import { RepairMdpcMajorHod } from "pages/repairlist_mdpc_hod";
import { RepairMdpcInspec } from "pages/repairlist_mdpc_inspec";
import { MjcRepairMdpcWaitAppointmentJobEdit } from "pages/repairlist_mdpc_admin/waiting_appointment_job";
import { MjcRepairMdpcDoneJobEdit } from "pages/repairlist_mdpc_admin/done_job";
import { MjcRepairMdpcInprogressJobEdit } from "pages/repairlist_mdpc_admin/inprogress_job";
import { MjcRepairMdpcNewJobCreate, MjcRepairMdpcNewJobEdit } from "pages/repairlist_mdpc_admin/new_job";
import { MjcRepairMdpcTodayJobEdit } from "pages/repairlist_mdpc_admin/today_job";
import { MjcRepairMdpcWaitCustApproveJobEdit } from "pages/repairlist_mdpc_admin/wait_cust_approve";
import { MjcRepairMdpcHodRealViewJob } from "pages/repairlist_mdpc_hod/real_view_job";
import { MjcRepairMdpcHodViewJob } from "pages/repairlist_mdpc_hod/view_job";
import { MjcRepairMdpcInspecJobEdit } from "pages/repairlist_mdpc_inspec/edit_job";
import { RepairMdpcSendToCustApprove } from "pages/repairlist_mdpc_inspec/send_to_cust_approve";
import { RepairMdpcMakeAppointment } from "pages/repairlist_mdpc_admin/make_an_appointment";
import { RepairSetupFacility } from "pages/repair_setup_facility";
import { RepairFacilityEmailGroupCreate, RepairFacilityEmailGroupEdit } from "pages/repair_setup_facility/email_group";
import { RepairFacilityTermCreate, RepairFacilityTermEdit } from "pages/repair_setup_facility/term_and_condition";
import { RepairMasterFacility } from "pages/repair_master_facility";
import { RepairFacilityZoneCreate, RepairFacilityZoneEdit, RepairFacilityZoneList } from "pages/repair_master_facility/zone";
import { RepairFacilityCategoryCreate, RepairFacilityCategoryEdit, RepairFacilityCategorylList } from "pages/repair_master_facility/category";
import { RepairFacilityAdmin } from "pages/repair_job_facility";
import { MjcRepairFacilityNewJobCreate, MjcRepairFacilityNewJobEdit } from "pages/repair_job_facility/new_job";
import { RepairDocument } from "pages/repair_document";
import { RepairDocumentCreate, RepairDocumentEdit } from "pages/repair_document/document_other";
import { RepairMdpcSendToCustApproveAdmin } from "pages/repairlist_mdpc_admin/send_to_cust_approve";
import { ApiLogList, ApiLogShow } from "pages/api_log_dev";
import { EstampList } from "pages/e_stamp";
import { EstampConfigCreate, EstampConfigEdit, EstampConfigList } from "pages/e_stamp_config";
import { PetmasterList } from "pages/pet-master";
import { PettypeCreate, PettypeEdit, PettypeList } from "pages/pet-master/pet-types";
import { PetvcCreate, PetvcEdit, PetvcList } from "pages/pet-master/vaccine";
import { PetspeciesCreate, PetspeciesEdit, PetspeciesList } from "pages/pet-master/pet-species";
import { ConfigpropCreate, ConfigpropEdit, ConfigpropList } from "pages/config_property";
import { PetVaccList } from "pages/pet_vac_list";
import { PetCommuList } from "pages/pet_community";
import { PetDataEdit, PetDataList, PetDetail } from "pages/pet_data";
import { PetDataGuestRegis } from "pages/pet_data/guest";
import { MjcRepairMdpcWaitApproveJobEdit } from "pages/repairlist_mdpc_admin/waiting_approv_job";
import { RentLocationMasterCreate, RentLocationMasterEdit, RSLocationList } from "pages/rent_and_sale/location";
import { RentSaleMasterList } from "pages/rent_and_sale";
import { RentSubLocationCreate, RentSubLocationEdit } from "pages/rent_and_sale/sub_location";
import { RentPropertyTypeCreate, RentPropertyTypeEdit, RentPropertyTypeList } from "pages/rent_and_sale/property_type";
import { RentRoomTypeCreate, RentRoomTypeEdit, RentRoomTypeList } from "pages/rent_and_sale/room_type";
import { RentPriceRangeCreate, RentPriceRangeEdit, RentPriceRangeList } from "pages/rent_and_sale/price_range";
import { RentSubTransportCreate, RentSubTransportEdit, RentSubTransportList } from "pages/rent_and_sale/sub_transport";
import { RentTransportCreate, RentTransportEdit, RentTransportList } from "pages/rent_and_sale/transport";
import { PetSizeEdit, PetSizeList } from "pages/pet-master/pet-size";
import { create } from "domain";
// import { MgnNewsEventCreate } from "pages/mgn_news_event/create";
// import { ContentSectionSeqCreate, ContentSectionSeqList } from "pages/content_section_seq";

const { RouterComponent } = routerProvider;
const CustomRouterComponent = () => <RouterComponent basename="/" />;


function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  

 let data: any = [
    {
      name: "page-null",
      list:listnull
    },
    {
      name: "user-profiles",
      options: {
        label: "User Managements",
      },
      icon: <UserOutlined />,
      list: UserManageList,
      create: UserManageCreate,
      edit: UserManageEdit,
      show: UserManageShow,
    },



    {
      name: "project_member",
      options: {
        label: "Project Member",
      },
      icon: <UserOutlined />,
    },
    {
      name: "user-account",
      parentName: "project_member",
      options: {
        label: "Member Account",
      },
      list: UserList,
      create: UserProfileCreate,
      edit: UserProfileEdit,
      show: UserProfileShow,
    },
    {
      name: "user-has-units",
      parentName: "project_member",
      options: {
        label: "Project Member",
      },
      list: UserhunitList,
      create: UserhunitCreate,
      edit: UserhunitEdit,
    },





    {
      name: "propertie",
      options: {
        label: "Property",
      },
      icon: <BankOutlined />,
    },
    {
      name: "properties",
      parentName: "propertie",
      options: {
        label: "Properties",
      },
      list: PropertiesList,
      create: PropertiesCreate,
      edit: PropertiesEdit,
      show: PropertiesShow,
    },
    {
      name: "pp-types",
      parentName: "propertie",
      options: {
        label: "Properties type",
      },
      list: PPtypesList,
      create: PPtypesCreate,
      edit: PPtypesEdit,
      show: PPtypesShow,
    },
    {
      name: "unit",
      options: {
        label: "Unit",
      },
      icon: <ApartmentOutlined />,
    },
    {
      name: "units",
      parentName: "unit",
      options: {
        label: "Units",
      },
      list: UnitList,
      create: UnitCreate,
      edit: UnitEdit,
      show: UnitShow,
    },
    {
      name: "unit-types",
      parentName: "unit",
      options: {
        label: "Unit Type",
      },
      list: UnitTypeList,
      create: UnitTypeCreate,
      edit: UnitTypeEdit,
    },

    {
      name: "vehicle",
      options: {
        label: "Vehicles",
      },
      icon: <CarOutlined />,
    },
    {
      name: "vehicles",
      parentName: "vehicle",
      options: {
        label: "Vehicle",
      },
      list: VehicleList,
      create: VehicleCreate,
      edit: VehicleEdit,
    },
    {
      name: "vehicle-brands",
      parentName: "vehicle",
      options: {
        label: "Vehicle Brands",
      },
      list: VehicleBList,
      create: VehicleBCreate,
      edit: VehicleBEdit,
    },
    {
      name: "vehicle-colors",
      parentName: "vehicle",
      options: {
        label: "Vehicle Color",
      },
      list: VehicleCList,
      create: VehicleCCreate,
      edit: VehicleCEdit,
    },
    {
      name: "vehicle-models",
      parentName: "vehicle",
      options: {
        label: "Vehicle Models",
      },
      list: VehicleMList,
      create: VehicleMCreate,
      edit: VehicleMEdit,
    },
    {
      name: "vehicle-types",
      parentName: "vehicle",
      options: {
        label: "Vehicle Types",
      },
      list: VehicleTList,
      create: VehicleTCreate,
      edit: VehicleTEdit,
    },

    {
      name: "content_post",
      options: {
        label: "Content Post",
      },
      icon: <FileDoneOutlined />,
    },
    {
      name: "coupon-groups",
      parentName: "content_post",
      options: {
        label: "Coupon Group",
      },
      list: CouponGroupList,
      create: CouponGroupCreate,
      edit: CouponGroupEdit,
      show: CouponGroupShow,
    },
    {
      name: "content-promotions",
      parentName: "content_post",
      options: {
        label: "Promotion",
      },
      list: ContentPostPromotionList,
      create: ContentPostPromotionCreate,
      edit: ContentPostPromotionEdit,
      show: ContentPostPromotionShow,
    },
    {
      name: "content-aes",
      parentName: "content_post",
      options: {
        label: "Activity & Event",
      },
      list: ContentPostActivityEventList,
      create: ContentPostActivityEventCreate,
      edit: ContentPostActivityEventEdit,
      show: ContentPostActivityEventShow,
    },
    {
      name: "content-news",
      parentName: "content_post",
      options: {
        label: "News",
      },
      list: ContentPostNewsList,
      create: ContentPostNewsCreate,
      edit: ContentPostNewsEdit,
      show: ContentPostNewsShow,
    },
    {
      name: "content-blogs",
      parentName: "content_post",
      options: {
        label: "Blog",
      },
      list: ContentPostBlogList,
      create: ContentPostBlogCreate,
      edit: ContentPostBlogEdit,
      show: ContentPostBlogShow,
    },
    {
      name: "content-announcements",
      parentName: "content_post",
      options: {
        label: "Announcement",
      },
      list: ContentPostAnnounceList,
      create: ContentPostAnnouncementCreate,
      edit: ContentPostAnnouncementEdit,
      show: ContentPostAnnouncementShow,
    },
    {
      name: "privileges",
      parentName: "content_post",
      options: {
        label: "Privilege",
      },
      list: ContentPostPrivilegeList,
      create: ContentPostPrivilegeCreate,
      edit: ContentPostPrivilegeEdit,
      show: ContentPostPrivilegeShow,
    },
    {
      name: "manage-news-events",
      parentName: "content_post",
      options: {
        label: "Manage News And Event",
      },
      list: MgnNewsEvent,
      create: MgnNewsEventCreate,
      edit: MgnNewsEventEdit,
    },
    {
      name: "phone_book",
      options: {
        label: "Phone Book",
      },
      icon: <BookOutlined />,
    },
    {
      name: "phone-books",
      parentName: "phone_book",
      options: {
        label: "Phone Books",
      },
      list: PhoneBooksList,
      create: PhoneBooksCreate,
      edit: PhoneBooksEdit,
      show: PhoneBooksShow,
    },
    {
      name: "phone-book-types",
      parentName: "phone_book",
      options: {
        label: "Phone Book Type",
      },
      list: PhoneBooksTypeList,
      edit: PhoneBooksTypeEdit,
      create: PhoneBooksTypeCreate,
    },
    // jira-1130 ปิดเพราะโมบายปิด 
    // {
    //   name: "policies",
    //   // parentName: "",
    //   options: {
    //     label: "Policy",
    //   },
    //   icon: <SafetyCertificateOutlined />,
    //   list: PolicyList,
    //   create: PolicyCreate,
    //   edit: PolicyEdit,
    // },

    {
      name: "term-and-conditions",
      // parentName: "",
      options: {
        label: "Term and Conditions",
      },
      icon: <IssuesCloseOutlined />,
      list: TermList,
      create: TermCreate,
      edit: TermEdit,
    },

    {
      name: "project",
      options: {
        label: "Project",
      },
      icon: <SnippetsOutlined />,
    },
    {
      name: "projects",
      parentName: "project",
      options: {
        label: "Projects",

      },
      list: ProjectList,
      create: ProjectCreate,
      edit: ProjectEdit,
      show: ProjectShow,
    },
    {
      name: "project-infos",
      parentName: "project",
      options: {
        label: "Project-info",
      },
      list: ProjectinfoList,
      create: ProjectinfoCreate,
      edit: ProjectinfoEdit,
      show: ProjectinfoShow,
    },
    {
      name: "appointment",
      options: {
        label: "Appointment",
      },
      icon: <ScheduleOutlined />,
    },
    {
      name: "appointments",
      parentName: "appointment",
      options: {
        label: "Appointments",
      },
      list: AppointmList,
      create: AppointmCreate,
      edit: AppointmEdit,
      show: AppointmShow,
    },
    {
      name: "appointment-types",
      parentName: "appointment",
      options: {
        label: "Appointments-type",
      },
      list: AppointmTypeList,
      create: AppointmTypeCreate,
      edit: AppointmTypeEdit,
      show: AppointmTypeShow,
    },

    {
      name: "facility",
      options: {
        label: "Facilities",
      },
      icon: <EnvironmentOutlined />,
    },
    {
      name: "facilities", // check
      parentName: "facility",
      options: {
        label: "Facility",
      },
      list: FacilitiesList,
      create: FacilitiesCreate,
      edit: FacilitiesEdit,
      show: FacilitiesShow,
    },
    {
      name: "facility-bookings",  // check
      parentName: "facility",
      options: {
        label: "Facility Booking",
      },
      list: FacilitiesBKList,
      create: FacilitiesBKCreate,
      edit: FacilitiesBKEdit,
      show: FacilitiesBKShow,
    },
    {
      name: "facility-wait-lists",
      parentName: "facility",
      options: {
        label: "Facility Waiting List",
      },
      list: FacilityWaitlist,
    },
    {
      name: "facility-bans",  // check
      parentName: "facility",
      options: {
        label: "Facility Ban User",
      },
      list: FacilitiesBanList,
      create: FacilitiesBanCreate,
      edit: FacilitiesBanEdit,
      show: FacilitiesBanShow,
    },
    {
      name: "facility_setup", // new check
      // parentName: "facility",
      icon: <EnvironmentOutlined />,
      options: {
        label: "Facility Setup",
      },
    },

    {
      name: "facility-types", // new check
      parentName: "facility_setup",
      options: {
        label: "Facility Type",
      },
      list: FacilitiesTypeList,
      create: FacilitiesTypeCreate,
      edit: FacilitiesTypeEdit,
      show: FacilitiesTypeShow,
    },
    {
      name: "facility-conditions", // new check
      parentName: "facility_setup",
      options: {
        label: "Facility Condition",
      },
      list: FacilitiesCondiList,
      create: FacilitiesCondiCreate,
      edit: FacilitiesCondiEdit,
    },
    {
      name: "facility-gen-checkins", // new check
      parentName: "facility_setup",
      options: {
        label: "Facility Check-in QR",
      },
      list: FaciiltyGenCheckInList,
      create: FaciiltyGenCheckInCreate,
      edit: FaciiltyGenCheckInEdit,
      show: FaciiltyGenCheckInShow,
    },
    {
      name: "config_system",
      options: {
        label: "Config System",
      },
      icon: <SettingOutlined />,
    },
    {
      name: "companies",
      parentName: "config_system",
      options: {
        label: "Company Setup",
      },
      list: CompanyList,
      create: CompanyCreate,
      edit: CompanyEdit,
    },
    {
      name: "role-managements",
      parentName: "config_system",
      options: {
        label: "Role Management",
      },
      list: RoleList,
      create: RoleCreate,
      edit: RoleEdit,
    },
    {
      name: "banner-managements",
      parentName: "content_post",
      options: {
        label: "Banner Management",
      },
      list: BannerMgnList,
      create: BannerMgnCreate,
      edit: BannerMgnEdit,
    },
    {
      name: "popup-managements",
      parentName: "config_system",
      options: {
        label: "Popup Management",
      },
      list: PopupMgnList,
      create: PopupMgnCreate,
      edit: PopupMgnEdit,
    },
    // {
    //   name: "module-masters",
    //   parentName: "config_system",
    //   options: {
    //     label: "Menu Management",
    //   },
    //   list: MenuManageList,
    //   create: MenuManageCreate,
    //   edit: MenuManageEdit,
    // },
    {
      name: "contact-centers",
      parentName: "config_system",
      options: {
        label: "Contact Center",
      },
      list: ContactCenterList,
      create: ContactCenterCreate,
      edit: ContactCenterEdit,
    },
    {
      name: "juristic-working-days",
      parentName: "config_system",
      options: {
        label: "Juristic Working Day",
      },
      list: JuristicWorkingDayList,
    },

    {
      name: "master",
      options: {
        label: "Master",
      },
      icon: <UserOutlined />,
    },
    {
      name: "project-brands",
      parentName: "master",
      options: {
        label: "Project Brands",
      },
      list: ProjectBrandList,
      create: ProjectBrandCreate,
      edit: ProjectBrandEdit,
    },
    {
      name: "parcel-types",
      parentName: "master",
      options: {
        label: "Parcel Types",
      },
      list: ParcelTypeList,
      create: ParcelTypeCreate,
      edit: ParcelTypeEdit,
    },
    {
      name: "parcel-box-sizes",
      parentName: "master",
      options: {
        label: "Parcel Box Sizes",
      },
      list: ParcelBoxList,
      create: ParcelBoxCreate,
      edit: ParcelBoxEdit,
    },
    {
      name: "parcel-shipping-types",
      parentName: "master",
      options: {
        label: "Parcel Shipping Types",
      },
      list: ParcelShippingList,
      create: ParcelShippingCreate,
      edit: ParcelShippingEdit,
    },
    {
      name: "delivery-companies",
      parentName: "master",
      options: {
        label: "Delivery Company",
      },
      list: DeliveryCompList,
      create: DeliveryCompCreate,
      edit: DeliveryCompEdit,
    },

    {
      name: "parcel",
      options: {
        label: "Parcel",
      },
      icon: <GiftOutlined />,
    },
    {
      name: "parcel-ins",
      parentName: "parcel",
      options: {
        label: "Parcel In",
      },
      list: ParcelInList,
      create: ParcelInCreate,
      edit: ParcelInEdit,
    },
    {
      name: "parcel-lists",
      parentName: "parcel",
      options: {
        label: "Parcel List",
      },
      list: ParcelListList,
      edit: ParcelListEdit,
    },
    {
      name: "parcel-ins-create",
      parentName: "parcel",
      options: {
        label: "Parcel Create",
      },
      list: ParcelInCreate,
    },
    {
      name: "document",
      options: {
        label: "Document",
      },
      icon: <FileOutlined />,
    },
    {
      name: "documents",
      parentName: "document",
      options: {
        label: "Document",
      },
      list: DocList,
      create: DocumentCreate,
    },
    {
      name: "document-types",
      parentName: "document",
      options: {
        label: "Document Type",
      },
      list: DocTypeList,
    },
    {
      name: "document-sub-types",
      parentName: "document",
      options: {
        label: "Document Sub Type",
      },
      list: DocSubTypeList,
    },

    {
      name: "billing",
      options: {
        label: "Billing",
      },
      icon: <FileOutlined />,
    },
    {
      name: "config-payments",
      parentName: "billing",
      options: {
        label: "Config Payment",
      },
      list: ConfigPaymentList,
    },
    {
      name: "billing-invoices",
      parentName: "billing",
      options: {
        label: "Billing Invoices",
      },
      list: BillingInvoiceList,
    },
    {
      name: "receipts",
      parentName: "billing",
      options: {
        label: "Receipt",
      },
      list: ReceiptList,
    },
    {
      name: "receipt-cancels",
      parentName: "billing",
      options: {
        label: "Receipt Cancel",
      },
      list: ReceiptCancelList,
    },
    {
      name: "billing-payments",
      parentName: "billing",
      options: {
        label: "Deposit",
      },
      list: BillingpaymentList,
    },
    {
      name: "billing-payment-notis",
      parentName: "billing",
      options: {
        label: "Payment Notification",
      },
      list: PaymentNotiList,
    },

    // {
    //   name: "suggestion",
    //   options: {
    //     label: "Suggestion",
    //   },
    //   icon: <QuestionCircleOutlined />,
    // },
    // {
    //   name: "suggestions",
    //   parentName: "suggestion",
    //   options: {
    //     label: "Poll",
    //   },
    //   list: SuggestionList,
    // },



    {
      name: "majorcare",
      options: {
        // label: "Major Care"
        label: "Services & Repair"
      },
      icon: <HeartOutlined />,
    },
    {
      name: "setup-hcu",
      parentName: "majorcare",
      options: {
        label: "Home check up setup",
      },
      list: HcuSetup,
    },
    {
      name: "notification",
      options: {
        label: "notification",
      },
      create: HcuNotiMsgCreate,
      edit: HcuNotiMsgEdit,
    },
    {
      name: "hcusetupdays",
      options: {
        label: "HOME CHECKUP SETUP DAYS",
      },
      create: HcuDaysCreate,
      edit: HcuDaysEdit,
    },
    {
      name: "termandcondition",
      options: {
        label: "Information",
      },
      create: HcuTermCreate,
      edit: HcuTermEdit,
    },
    {
      name: "assigninspec",
      options: {
        label: "Inspector Assign",
      },
      create: HcuAssignCreate,
      edit: HcuAssignEdit,
    },

    {
      name: "master-hcu",
      parentName: "majorcare",
      options: {
        label: "Home check up master data",
      },
      list: HcuMaster,
    },
    {
      name: "mjc-zone",
      options: {
        label: "Zone master",
      },
      create: HcuZoneCreate,
      edit: HcuZoneEdit,
    },
    {
      name: "mjc-structure",
      options: {
        label: "Structure master",
      },
      create: HcuStructureCreate,
      edit: HcuStructureEdit,
    },
    {
      name: "mjc-checklist",
      options: {
        label: "Checklist master",
      },
      create: HcuChecklistCreate,
      edit: HcuChecklistEdit,
    },

    {
      name: "mjc-job",
      parentName: "majorcare",
      options: {
        label: "Home check up",
      },
      list: HcuRepairList,
    },
    {
      name: "mjc-job-newjob",
      options: {
        label: "New Job",
      },
      list: MjcNewJob,
      edit: MjcNewJobEdit,
    },
    {
      name: "mjc-job-waiting",
      options: {
        label: "Waiting for Appointment",
      },
      list: MjcWaiting,
      edit: MjcWaitingEdit,
    },
    {
      name: "mjc-job-in-progress",
      options: {
        label: "Inprogress Job",
      },
      list: MjcInProgress,
      edit: MjcInProgressEdit,
    },
    {
      name: "mjc-done",
      options: {
        label: "Done Job",
      },
      list: MjcDone,
      edit: MjcDoneEdit,
    },
    {
      name: "mjc-inspec",
      options: {
        label: "Home Checkup (สำหรับช่าง)",
      },
      icon: <HeartOutlined />,
      list: HcuRepairInspecList,
    },
    {
      name: "mjc-inspec-today-job",
      options: {
        label: "Inspector Job",
      },
      icon: <HeartOutlined />,
      list: MjcInspecTodayJobList,
      edit: MjcInspecTodayJobEdit,
    },
    {
      name: "mjc-inspec-new-job",
      options: {
        label: "Inspector New Job",
      },
      icon: <HeartOutlined />,
      list: MjcInspecNewJobList,
      edit: MjcInspecNewJobEdit,
    },
    {
      name: "mjc-inspec-inprogress-job",
      options: {
        label: "Inspector New Job",
      },
      icon: <HeartOutlined />,
      list: MjcInspecInprogressJobList,
      edit: MjcInspecInprogressEdit,
    },
    {
      name: "mjc-inspec-done-job",
      options: {
        label: "Inspector Done Job",
      },
      icon: <HeartOutlined />,
      list: MjcInspecDoneJobList,
      edit: MjcInspecDoneJobEdit,
    },
    {
      name: "template",
      options: {
        label: "Template Master",
      },
      create: HcutempMastercreate,
      edit: HcutempMasteredit,
    },
    {
      name: "mjc-inspec-checklist-todayjob-edit",
      options: {
        label: "Checklist Edit",
      },
      edit: MjcInspecEditChecklistt,
    },
    {
      name: "mjc-email-group",
      options: {
        label: "Email Group"
      },
      create: HcuEmailGroupCreate,
      list: HcuEmailGroupList,
      edit: HcuEmailGroupEdit,
    },






    {
      name: "repair",
      options: {
        label: "Major แจ้งซ่อม"
      },
      icon: <HeartOutlined />,
    },
    {
      name: "repair-setup",
      parentName: "repair",
      options: {
        label: "Setup แจ้งซ่อม (Major Care)",
      },
      list: Repair,
    },
    {
      name: "repair-tnc",
      // parentName: "repair-setup",
      options: {
        label: "Information"
      },
      create: RepairTermCreate,
      edit: RepairTermEdit,
    },
    {
      name: "repair-assign-technician",
      // parentName: "repair-setup",
      options: {
        label: "Assign Technician"
      },
      create: RepairAssignCreate,
      edit: RepairAssignEdit,
    },
    {
      name: "repair-email-group",
      // parentName: "repair-setup",
      options: {
        label: "Email Group"
      },
      create: RepairEmailGroupCreate,
      edit: RepairEmailGroupEdit,
    },


    {
      name: "repair-mdpc",
      options: {
        label: "MDPC แจ้งซ่อม"
      },
      icon: <HeartOutlined />,
    },
    {
      name: "repair-mdpc-setup",
      parentName: "repair-mdpc",
      options: {
        label: "Setup แจ้งซ่อม (MDPC)",
      },
      list: RepairMdpc,
    },
    {
      name: "repair-mdpc-tnc",
      // parentName: "repair-setup",
      options: {
        label: "Information"
      },
      create: RepairMdpcTermCreate,
      edit: RepairMdpcTermEdit,
    },
    {
      name: "repair-mdpc-assign-technician",
      // parentName: "repair-setup",
      options: {
        label: "Assign Technician"
      },
      create: RepairMdpcAssignCreate,
      edit: RepairMdpcAssignEdit,
    },
    {
      name: "repair-mdpc-email-group",
      // parentName: "repair-setup",
      options: {
        label: "Email Group"
      },
      create: RepairMdpcEmailGroupCreate,
      edit: RepairMdpcEmailGroupEdit,
    },
    {
      name: "repair-mdpc-job",
      parentName: "repair-mdpc",
      options: {
        label: "รายการแจ้งซ่อม",
      },
      list: RepairMdpcAdmin,
    },
    {
      name: "repair-mdpc-hod",
      options: {
        label: "MDPC แจ้งซ่อม (สำหรับหัวหน้างาน)",
      },
      icon: <HeartOutlined />,
      list: RepairMdpcMajorHod,
    },
    {
      name: "repair-mdpc-inspec",
      options: {
        label: "MDPC แจ้งซ่อม (สำหรับช่าง)",
      },
      icon: <HeartOutlined />,
      list: RepairMdpcInspec,
    },
    {
      name: "repair-mdpc-admin-wait-appointment",
      options: {
        label: "MDPC Admin รอนัดหมาย",
      },
      edit: MjcRepairMdpcWaitAppointmentJobEdit,
    },
    {
      name: "repair-mdpc-done-job-admin",
      options: {
        label: "MDPC Admin เสร็จสิ้น",
      },
      edit: MjcRepairMdpcDoneJobEdit,
    },
    {
      name: "repair-mdpc-inprogress-job-admin",
      options: {
        label: "MDPC Admin ระหว่างดำเนินการ",
      },
      edit: MjcRepairMdpcInprogressJobEdit,
    },
    {
      name: "repair-mdpc-new-job-admin",
      options: {
        label: "MDPC Admin รายการใหม่",
      },
      create: MjcRepairMdpcNewJobCreate,
      edit: MjcRepairMdpcNewJobEdit,
    },
    {
      name: "repair-mdpc-today-job-admin",
      options: {
        label: "MDPC Admin รายการวันนี้",
      },
      edit: MjcRepairMdpcTodayJobEdit,
    },
    {
      name: "repair-mdpc-wait-cust-approve-job-admin",
      options: {
        label: "MDPC Admin รอลูกบ้านอนุมัติ",
      },
      edit: MjcRepairMdpcWaitCustApproveJobEdit,
    },
    {
      name: "repair-mdpc-wait-approve-job-admin",
      options: {
        label: "MDPC Admin รออนุมัติงานซ่อม",
      },
      edit: MjcRepairMdpcWaitApproveJobEdit,
    },
    {
      name: "repair-mdpc-send-to-cust-approve",
      options: {
        label: "ส่งให้ลูกค้าอนุมัติ",
      },
      edit: RepairMdpcSendToCustApproveAdmin
    },


    {
      name: "repair-mdpc-make-appointment",
      options: {
        label: "ทำรายการแจ้งซ่อม",
      },
      edit: RepairMdpcMakeAppointment
    },

    {
      name: "repair-mdpc-hod-real-view-job",
      options: {
        label: "MDPC HOD real view",
      },
      edit: MjcRepairMdpcHodRealViewJob,
    },
    {
      name: "repair-mdpc-hod-view-job",
      options: {
        label: "MDPC HOD view",
      },
      edit: MjcRepairMdpcHodViewJob,
    },


    {
      name: "repair-mdpc-inspec-edit-job",
      options: {
        label: "MDPC INSPEC view",
      },
      edit: MjcRepairMdpcInspecJobEdit,
    },
    {
      name: "repair-mdpc-inspec-send-to-cust",
      options: {
        label: "MDPC INSPEC send to cust approve",
      },
      edit: RepairMdpcSendToCustApprove,
    },


    {
      name: "repair-master",
      parentName: "repair",
      options: {
        label: "Master แจ้งซ่อม",
      },
      list: RepairMaster,
    },
    {
      name: "repair-zone",
      // parentName: "repair-master",
      options: {
        label: "Master โซนซ่อม",
      },
      list: RepairZoneList,
      create: RepairZoneCreate,
      edit: RepairZoneEdit
    },
    {
      name: "repair-category",
      // parentName: "repair-master",
      options: {
        label: "Master หมวด",
      },
      list: RepairCategoryAndDetailList,
      create: RepairCategoryCreate,
      edit: RepairCategoryEdit
    },
    {
      name: "repair-category-detail",
      // parentName: "repair-master",
      options: {
        label: "Master รายละเอียด",
      },
      create: RepairDetailCreate,
      edit: RepairDetailEdit
    },


    {
      name: "repair-job",
      parentName: "repair",
      options: {
        label: "รายการแจ้งซ่อม",
      },
      list: RepairMajorAdmin
    },
    {
      name: "repair-new-job-admin",
      parentName: "repair",
      options: {
        label: "รายการใหม่",
      },
      create: MjcRepairNewJobCreate,
      edit: MjcRepairNewJobEdit
    },
    {
      name: "repair-wait-cust-approve-job-admin",
      parentName: "repair",
      options: {
        label: "รายการรอลูกค้าอนุมัติ",
      },
      list: MjcRepairWaitingCustApproveJob,
      edit: MjcRepairWaitCustApproveJobEdit
    },
    {
      name: "repair-today-job-admin",
      parentName: "repair",
      options: {
        label: "รายการวันนี้",
      },
      edit: MjcRepairTodayJobEdit
    },
    {
      name: "repair-inprogress-job-admin",
      parentName: "repair",
      options: {
        label: "ระหว่างดำเนินการ",
      },
      edit: MjcRepairInprogressJobEdit
    },
    {
      name: "repair-wait-approve-job-admin",
      parentName: "repair",
      options: {
        label: "รออนุมัติ",
      },
      edit: MjcRepairWaitApproveJobEdit
    },
    {
      name: "repair-done-job-admin",
      parentName: "repair",
      options: {
        label: "เสร็จสิ้น",
      },
      edit: MjcRepairDoneJobEdit
    },

    

    {
      name: "repair-make-appointment",
      options: {
        label: "ทำรายการแจ้งซ่อม",
      },
      edit: RepairMakeAppointment
    },
    {
      name: "repair-send-to-cust-approve",
      options: {
        label: "ส่งให้ลูกค้าอนุมัติ",
      },
      edit: RepairSendToCustApproveAdmin
    },
    {
      name: "repair-job-wait-appointment",
      options: {
        label: "รอนัดหมาย",
      },
      edit: MjcRepairWaitAppointmentJobEdit
    },

    {
      name: "repair-inspec",
      options: {
        label: "Major แจ้งซ่อม (สำหรับช่าง)",
      },
      icon: <HeartOutlined />,
      list: RepairMajorInspec,
    },
    {
      name: "repair-inspec-send-to-cust",
      options: {
        label: "ส่งให้ลูกค้าอนุมัติ",
      },
      edit: RepairSendToCustApprove
    },
    {
      name: "repair-inspec-edit-job",
      options: {
        label: "แก้ไขรายการ",
      },
      edit: MjcRepairInspecJobEdit
    },

    
    {
      name: "repair-hod",
      options: {
        label: "Major แจ้งซ่อม (สำหรับหัวหน้างาน)",
      },
      icon: <HeartOutlined />,
      list: RepairMajorHod,
    },
    {
      name: "repair-hod-today-job",
      options: {
        label: "รายการใหม่",
      },
      list: RepairHodTodayJobList
    },
    {
      name: "repair-hod-job-list",
      options: {
        label: "รายการแจ้งซ่อม",
      },
      list: RepairHodJobList
    },
    {
      name: "repair-hod-inprogress-job",
      options: {
        label: "ระหว่างดำเนินการ",
      },
      list: RepairHodInprogressJobList
    },
    {
      name: "repair-hod-wait-approve-job",
      options: {
        label: "รออนุมัติ",
      },
      list: RepairHodWaitApproveJobList
    },
    {
      name: "repair-hod-done-job",
      options: {
        label: "เสร็จสิ้น",
      },
      list: RepairHodDoneJobList
    },
    {
      name: "repair-hod-view-job",
      options: {
        label: "แก้ไขรายการ",
      },
      edit: MjcRepairHodViewJob
    },
    {
      name: "repair-hod-real-view-job",
      options: {
        label: "ดูรายการ",
      },
      edit: MjcRepairHodRealViewJob
    },


    {
      name: "repair-facility",
      options: {
        label: "แจ้งซ่อมส่วนกลาง"
      },
      icon: <HeartOutlined />,
    },
    {
      name: "repair-facility-setup",
      parentName: "repair-facility",
      options: {
        label: "Setup แจ้งซ่อมส่วนกลาง",
      },
      list: RepairSetupFacility,
    },
    {
      name: "repair-facility-email-group",
      options: {
        label: "Email Group"
      },
      create: RepairFacilityEmailGroupCreate,
      edit: RepairFacilityEmailGroupEdit,
    },
    {
      name: "repair-facility-tnc",
      options: {
        label: "Term & Condition"
      },
      create: RepairFacilityTermCreate,
      edit: RepairFacilityTermEdit,
    },


    {
      name: "repair-facility-master", 
      parentName: "repair-facility",
      options: {
        label: "Master แจ้งซ่อมส่วนกลาง",
      },
      list: RepairMasterFacility,
    },
    {
      name: "repair-facility-zone",
      options: {
        label: "Master โซนซ่อม",
      },
      list: RepairFacilityZoneList,
      create: RepairFacilityZoneCreate,
      edit: RepairFacilityZoneEdit
    },
    {
      name: "repair-facility-category",
      options: {
        label: "Master หมวด",
      },
      list: RepairFacilityCategorylList,
      create: RepairFacilityCategoryCreate,
      edit: RepairFacilityCategoryEdit
    },
    
    {
      name: "repair-facility-job", 
      parentName: "repair-facility",
      options: {
        label: "รายการแจ้งซ่อมส่วนกลาง",
      },
      list: RepairFacilityAdmin,
    },

    {
      name: "repair-facility-new-job", 
      options: {
        label: "รายการแจ้งซ่อมส่วนกลาง",
      },
      create: MjcRepairFacilityNewJobCreate,
      edit: MjcRepairFacilityNewJobEdit,
    },
    // {
    //   name: "repair-facility-inprogress-job", 
    //   options: {
    //     label: "รายการแจ้งซ่อมส่วนกลาง",
    //   },
    //   edit: MjcRepairFacilityNewJobEdit,
    // },

    {
      name: "repair-document",
      options: {
        // label: "Services & Repair Document"
        label: t("menu.service-repair-doc")
      },
      icon: <HeartOutlined />,
      list: RepairDocument,
    },
    {
      name: "repair-document-other",
      options: {
        label: "Services & Repair Document"
      },
      create: RepairDocumentCreate,
      edit: RepairDocumentEdit,
    },


    {
      name: "api-log-dev",
      options: {
        label: "api-log-dev-only"
      },
      list: ApiLogList,
      show: ApiLogShow,
    },

    {
      name: "e-stamp",
      options: {
        label: "E-Stamp"
      },
      icon: <SmileOutlined />,
      list: EstampList,
    },
    {
      name: "visitor-stamp-configs",
      options: {
        label: "E-Stamp Config"
      },
      icon: <SmileOutlined />,
      list: EstampConfigList,
      create: EstampConfigCreate,
      edit: EstampConfigEdit,
    },






    {
      name: "pets",
      options: {
        label: "Pet Service"
      },
      icon: <GiftOutlined />,
    },
    {
      name: "pet-masters",
      parentName: "pets",
      options: {
        label: "Pet Master",
      },
      list: PetmasterList,
    },
    {
      name: "pet-types",
      list: PettypeList,
      create: PettypeCreate,
      edit: PettypeEdit,
    },
    {
      name: "pet-species",
      list: PetspeciesList,
      create: PetspeciesCreate,
      edit: PetspeciesEdit,
    },
    {
      name: "pet-vaccine",
      list: PetvcList,
      create: PetvcCreate,
      edit: PetvcEdit,
    },
    {
      name: "pet-size",
      list: PetSizeList,
      // create: PetvcCreate,
      edit: PetSizeEdit,
    },
    {
      name: "config-property",
      parentName: "pets",
      options: {
        label: "Config Property",
      },
      list: ConfigpropList,
      create: ConfigpropCreate,
      edit: ConfigpropEdit,
      // edit: PettypeEdit,
    },
    {
      name: "pet-vac-list",
      parentName: "pets",
      options: {
        label: "Pet Vaccine Records",
      },
      list: PetVaccList,
    },
    {
      name: "pet-commu",
      parentName: "pets",
      options: {
        label: "Pet Community",
      },
      list: PetCommuList,
    },
    {
      name: "pet-data",
      parentName: "pets",
      options: {
        label: "Pet Registration",
      },
      list: PetDataList,
      edit: PetDataEdit
    },
    {
      name: "suggestion",
      options: {
        label: "Suggestion",
      },
      list: SuggestionList,
      icon: <QuestionCircleOutlined />,
    },
    {
      name: "rent-sale",
      options: {
        label: "Rent & Sale"
      },
      icon: <DollarOutlined />,
    },
    {
      name: "rentsale-masters",
      parentName: "rent-sale",
      options: {
        label: "Master",
      },
      list: RentSaleMasterList,
    },
    {
      name: "master-location",
      parentName: "rent-sale",
      options: {
        label: "Location",
      },
      // list: RSLocationList,
      create: RentLocationMasterCreate,
      edit: RentLocationMasterEdit
    },
    {
      name: "master-sub-location",
      parentName: "rent-sale",
      options: {
        label: "Location Detail",
      },
      // list: RSLocationList,
      create: RentSubLocationCreate,
      edit: RentSubLocationEdit
    },
    {
      name: "master-prop-type",
      parentName: "rent-sale",
      options: {
        label: "Property Type",
      },
      list: RentPropertyTypeList,
      create: RentPropertyTypeCreate,
      edit: RentPropertyTypeEdit,
    },
    {
      name: "master-bedroom",
      parentName: "rent-sale",
      options: {
        label: "Room Type",
      },
      list: RentRoomTypeList,
      create: RentRoomTypeCreate,
      edit: RentRoomTypeEdit,
    },
    {
      name: "master-bts-mrt",
      parentName: "rent-sale",
      options: {
        label: "Transportation",
      },
      list: RentTransportList,
      create: RentTransportCreate,
      edit: RentTransportEdit,
    },
    {
      name: "master-sub-transport",
      parentName: "rent-sale",
      options: {
        label: "Transportation Detail",
      },
      list: RentSubTransportList,
      create: RentSubTransportCreate,
      edit: RentSubTransportEdit,
    },
    {
      name: "master-price-range",
      parentName: "rent-sale",
      options: {
        label: "Price Range",
      },
      list: RentPriceRangeList,
      create: RentPriceRangeCreate,
      edit: RentPriceRangeEdit,
    },
  ]

  return (
    <StorePermission>
      <Refine
        notificationProvider={notificationProvider}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={{
          ...routerProvider,
          RouterComponent: CustomRouterComponent,
          routes:[
            {
              element: <ContentPostPromotionShowUNTOKEN />,
              path: "/content/ae/:id/:local",
            },
            {
              element: <PetDetail />,
              path: "/pets/:id/:local",
            },
            {
              element: <PetDataGuestRegis />,
              path: "/pet-guest-regis",
            },
            {
              element: <EstampList />,
              path: "/e-stamp",
            },
          ]
        }}
        authProvider={authProvider}
        dataProvider={{
          default: DataProvider(API_URL + `/api`, axiosInstance),
          majorCareUrl:DataProvider("https://api-dev-majorcare.techscape.app" + `/api`, axiosInstance) ,
        }}
        LoginPage={LoginPage}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
        resources={data}
      >
        
      </Refine>
      {/* <Routes> */}
          {/* <Route
              path="/select-project"
              element= {
                    <ContentPostPromotionShowUNTOKEN />
              }
          /> */}
        {/* </Routes> */}
    </StorePermission>
    
  );
}

export default App;
